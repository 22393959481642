/**
 * @description class used to hanlde audio input level
 * @export
 * @class AudioInputLevel
 */
export class AudioInputLevel {
  constructor(options) {
    this.options = {
      analyserFrequent: 50,
      ...(options || {}),
    };
    this.asnTime = performance.now();
    this.audioStream = null;
    this.analyserNodeBufferDataArray = null;
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    this.destinationNode = new MediaStreamAudioDestinationNode(this.audioCtx);
    this.initAnalyserNode();
  }
  /**
   * @description init analyserNode
   * @protected
   * @private
   */
  initAnalyserNode() {
    this.analyserNode = this.audioCtx.createAnalyser();
    this.analyserNode.connect(this.destinationNode);
    this.analyserNode.fftSize = 1024;
  }

  setAudioStream(stream) {
    this.audioStream = stream;
  }

  /**
   * @description start analyze
   * @public
   * @param [deviceId] set current microphone deviceId
   */
  start() {
    if (this.getAnalyzingStatus()) {
      this.stop();
    }
    if (this.getDestroyedStatus())
      return Promise.reject(new Error('instance is destroyed already'));
    return new Promise((resolve) => {
      this.setAnalyzingStatus(true);
      this.sourceNode = this.audioCtx.createMediaStreamSource(this.audioStream);
      this.sourceNode.connect(this.analyserNode);
      this.resumeAudioCtx();
      this.setAnalyzeInterval();
      resolve(true);
    });
  }
  /**
   * @description stop analyze
   */
  stop() {
    this.setAnalyzingStatus(false);
    this.clearAnalyzeInterval();
  }
  /**
   * @description destroy current instance
   */
  destroy() {
    this.stop();
    if (this.analyserNode && this.destinationNode) {
      this.analyserNode.disconnect(this.destinationNode);
    }
    this.setDestroyedStatus(true);
    this.audioCtx.suspend().finally(() => this.audioCtx.close());
    this.audioStream = null;
    this.analyserNodeBufferDataArray = null;
  }
  /**
   * @description interval callback for analyserNode
   * @protected
   * @private
   */
  analyserNodeIntervalCallback() {
    if (!this.analyserNodeBufferDataArray) return;
    this.analyserNode.getByteFrequencyData(this.analyserNodeBufferDataArray);
    let volumeSum = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const volume of this.analyserNodeBufferDataArray) volumeSum += volume;
    const averageVolume = volumeSum / this.analyserNodeBufferDataArray.length;
    if (averageVolume > 0) {
      const latestAsnTime = performance.now();
      if (latestAsnTime - this.asnTime > 1000) {
        this.asnTime = latestAsnTime;
        this.options.analyserCallback(averageVolume);
      }
    }
  }
  /**
   * @description set analyserNode interval
   * @protected
   * @private
   */
  setAnalyzeInterval() {
    this.clearAnalyzeInterval();
    const bufferLength = this.analyserNode.frequencyBinCount;
    this.analyserNodeBufferDataArray = new Uint8Array(bufferLength);
    this.analyserNodeTimer = window.setInterval(
      this.analyserNodeIntervalCallback.bind(this),
      this.options.analyserFrequent
    );
  }
  /**
   * @description clear analyserNode interval
   * @protected
   * @private
   */
  clearAnalyzeInterval() {
    if (this.analyserNodeTimer) {
      window.clearInterval(this.analyserNodeTimer);
      this.analyserNodeTimer = null;
      this.analyserNodeBufferDataArray = null;
    }
  }

  getAnalyzingStatus() {
    return !!this.isAnalyzing;
  }
  setAnalyzingStatus(flag) {
    this.isAnalyzing = !!flag;
  }
  getDestroyedStatus() {
    return !!this.isDestroyed;
  }
  setDestroyedStatus(destroyed) {
    this.isDestroyed = !!destroyed;
  }
  /**
   * @description resume audio ctx state
   */
  resumeAudioCtx() {
    if (this.audioCtx.state !== 'running') {
      this.audioCtx.resume();
    }
  }
}

/* Same as Native audio level indicator */

// Number of bars on the indicator.
// Note that the number of elements is specified because we are indexing it
// in the range of 0-32
const permutation = [
  0, 1, 2, 3, 4, 4, 5, 5, 5, 5, 6, 6, 6, 6, 6, 7, 7, 7, 7, 8, 8, 8, 9, 9, 9, 9,
  9, 9, 9, 9, 9, 9, 9,
]; // length 33

/*
	audioLevel: [0-1]
	audioLevel is samples abs_max value normalized within 100ms
	For WebRTC Solution: getting from RTCPSender.getStats() -> type=="media-source"
	For Preview: getting from AudioWorklet process -> calculate 100ms abs_max value
	For Webassembly Solution: get from WebAssembly directly
*/
export function getAudioLevelFromNormalized(audioLevel) {
  if (typeof audioLevel != 'number' || audioLevel < 0 || audioLevel > 1)
    return -1;

  const absMax = audioLevel * 32767;
  let position = parseInt(absMax / 1000);
  // Make it less likely that the bar stays at position 0. I.e. only if
  // its in the range 0-250 (instead of 0-1000)
  if (position == 0 && absMax > 250) {
    position = 1;
  }
  return permutation[position];
}

import IsHeadset from '../common/audio-headset';
import * as jsEvent from '../common/jsEvent';
import util from '../common/util';
import jsMediaEngineVariables from './JsMediaEngine_Variables';
import * as jsMediaEngine from '../lib/JsMediaEngine';

/*
ostype:typedef enum{
	A_UNKNOWN_CLI = 0,
    A_WIN_CLI,
    A_MAC_CLI,
	  A_PAD_CLI,
    A_MOBILE_CLI,
    A_CALL_IN_CLI,
    A_LINUX_CLI,
    A_WEB_CLI,

    // dylan.ye : vdi client flag
    A_VDI_RESERVE_BG = VDI_CLT_BASE,
    A_VDI_RESERVE_ED = VDI_CLT_BASE_END-1,
}CmmClientOSType;
*/
const A_WEB_CLI = 7;
//number of mic in capture device
const NUM_OF_DEVICE = 1;

// webclient can't get sessionState/colorFormat/FECState
const SESSIONSTATE = -1;
const COLORFORMAT = -1;
const FECCSTATE = -1;

function DeviceManager() {}

DeviceManager.prototype.init = function () {
  this.micId = null;
  this.micLabel = null;
  this.speakerId = null;
  this.speakerLabel = null;
  this.cameraId = null;
  this.cameraLabel = null;
  this.startTime = Date.now();
  this.userId = 0;
  this.videoWidth = 0;
  this.videoHeight = 0;
  this.isAudioBridge = false;
  this.isUpdatingDevice = false;
  this.denoiseSwitch = false;
  this.listAllDevices = false;
};

DeviceManager.prototype.notifyDenoiseSetting = function () {
  if (!util.isSupportAudioDenoise() || !this.micId || this.isAudioBridge)
    return;
  let data = {
    command: 'audio_denoise_switch',
    switch: this.denoiseSwitch,
    isHeadSet: deviceManager.isHeadSet(),
  };
  jsMediaEngine.Notify_Audio_Encode_Thread(data);
};

DeviceManager.prototype.updateDeviceList = function () {
  navigator.mediaDevices.enumerateDevices().then((devices) => {
    devices.forEach((device) => {
      if (device.kind == 'audioinput') {
        this.sendDeviceInfo(
          -1,
          1,
          this.getIndex(device.label),
          device.deviceId,
          device.label,
          true
        );
      } else if (device.kind == 'audiooutput') {
        this.sendDeviceInfo(
          -1,
          0,
          this.getIndex(device.label),
          device.deviceId,
          device.label,
          true
        );
      }
    });
  });
};

DeviceManager.prototype.changeDenoiseSwitch = function (flag) {
  this.denoiseSwitch = flag;
  this.notifyDenoiseSetting();
};

DeviceManager.prototype.setIsAudioBridge = function (flag) {
  this.isAudioBridge = flag;
};

DeviceManager.prototype.setUserId = function (id) {
  if (typeof id !== 'number' || Number.isNaN(id)) return;
  this.userId = id;
  if (!this.listAllDevices) {
    this.listAllDevices = true;
    this.updateDeviceList();
  }
};

DeviceManager.prototype.updateSelectedMicDevices = function (
  deviceId,
  deviceLabel,
  elapsed_time,
  success
) {
  deviceLabel = deviceLabel?.replace(/\([\w,\d]+:[\w,\d]+\)/i, '')?.trim();

  if (success) {
    this.micId = deviceId;
    this.micLabel = deviceLabel;
  }

  this.notifyDenoiseSetting();
  this.sendDeviceInfo(
    elapsed_time,
    1,
    this.getIndex(this.micLabel),
    deviceId,
    deviceLabel,
    success,
    this.isHeadSet()
  );
};

DeviceManager.prototype.updateSelectedSpeakerDevices = async function (
  deviceId,
  elapsed_time,
  success
) {
  deviceId = deviceId || 'default';
  let deviceLabel = 'default';
  let deviceList = await navigator.mediaDevices.enumerateDevices();
  deviceList.forEach((device) => {
    if (device.kind === 'audiooutput' && device.deviceId === deviceId) {
      deviceLabel = device.label?.replace(/\([\w,\d]+:[\w,\d]+\)/i, '')?.trim();
    }
  });

  if (success) {
    this.speakerId = deviceId;
    this.speakerLabel = deviceLabel;
  }

  this.sendDeviceInfo(
    elapsed_time,
    0,
    this.getIndex(this.speakerLabel),
    deviceId,
    deviceLabel,
    success,
    this.isHeadSet(false)
  );
};

DeviceManager.prototype.updateSelectedCameraDevices = function (
  deviceId,
  deviceLabel,
  width,
  height,
  frameRate,
  elapsed_time,
  success
) {
  this.cameraId = deviceId;
  this.cameraLabel = deviceLabel?.replace(/\([\w,\d]+:[\w,\d]+\)/i, '')?.trim();
  this.videoWidth = width;
  this.videoHeight = height;
  this.videoFrameRate = frameRate;
  this.sendCameraInfo(elapsed_time, success, frameRate);
};

DeviceManager.prototype.isHeadSet = function (isMic = true) {
  let deviceLabel = null;
  if (isMic) {
    deviceLabel = this.micLabel;
  } else {
    deviceLabel = this.speakerLabel;
  }
  return IsHeadset(deviceLabel);
};

// 0: selected device
// -1 as index (<=> Default Communication Device)
// -2 Default endpoint device will be used

DeviceManager.prototype.getIndex = function (deviceLabel) {
  if (deviceLabel == 'default') return -1;
  else if (deviceLabel == 'communications') {
    if (util.isMac()) return 0;
    return -2;
  } else return 0;
};

//type: 0/1 is speaker/mic
DeviceManager.prototype.sendDeviceInfo = function (
  elapsed_time,
  type,
  index,
  deviceId,
  deviceLabel,
  success,
  isHeadSet
) {
  if (!this.userId) return;
  let log = null;
  let threadRunTime = Date.now() - this.startTime;
  if (elapsed_time == -1) {
    log =
      'WCL_AUDIOD-ALL,' +
      this.userId +
      ',' +
      type +
      ',' +
      index +
      ',' +
      deviceLabel +
      ',' +
      deviceId +
      ',' +
      success +
      ',' +
      elapsed_time +
      ',' +
      A_WEB_CLI +
      ',' +
      threadRunTime +
      ',' +
      NUM_OF_DEVICE;
  } else {
    log =
      'WCL_AUDIOD,' +
      this.userId +
      ',' +
      type +
      ',' +
      index +
      ',' +
      deviceLabel +
      ',' +
      deviceId +
      ',' +
      success +
      ',' +
      elapsed_time +
      ',' +
      A_WEB_CLI +
      ',' +
      threadRunTime +
      ',' +
      NUM_OF_DEVICE +
      ',' +
      (success ? 1 : 0) +
      ',' +
      isHeadSet;
  }
  jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
    evt: 4167,
    seq: 1,
    body: {
      data: log,
    },
  });
};

DeviceManager.prototype.sendCameraInfo = function (elapsed_time, success, fps) {
  if (!this.userId || this.isUpdatingDevice) return;
  let log = null;
  log =
    'WCL_CAMERA,' +
    this.userId +
    ',0,' +
    this.cameraLabel +
    ',' +
    success +
    ',' +
    elapsed_time +
    ',' +
    SESSIONSTATE +
    ',' +
    this.videoWidth +
    ',' +
    this.videoHeight +
    ',' +
    COLORFORMAT +
    ',' +
    fps +
    ',' +
    FECCSTATE;
  jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
    evt: 4167,
    seq: 1,
    body: {
      data: log,
    },
  });
};

let deviceManager = new DeviceManager();

export default deviceManager;

//
// if (elapsed_time == -1)
//    {
//        device_stream << "AUDIOD-ALL,"<< m_self_id << "," << type<<","<<index<<","
//                         << des.p_name<<","<<des.p_unique_id << "," << success << ","
//                         << elapsed_time << "," << m_machine_info.os_type<<","<<tick_fix_t::now()<<","<<des.num_of_devices;
//    }
//    else
//    {
//        bool isHeadset = false;
//        if (index != -3) get_device_property(type,-3,SSB_MC_AUDIO_DEVICE_PROPERTY_HEADSET,&isHeadset,sizeof(bool));
//        device_stream << "AUDIOD," << m_self_id << "," << type<<","<<index<<","<< des.p_name<<","<<des.p_unique_id << ","
//                         << success << "," << elapsed_time << "," << m_machine_info.os_type<<","<<tick_fix_t::now()<<","
//                         <<des.num_of_devices << "," << result << "," << isHeadset;
//    }

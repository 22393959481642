// Used to send logs to GlobalTracing. See https://zoomvideo.atlassian.net/wiki/spaces/ZWC/pages/2400558201/Logging+Guideline for details
class GlobalTracingLogger {
  constructor() {
    this._highFrequencyLogs = {};
  }

  setInstance(instance) {
    this._instance = instance;
  }

  getMessageFromErrorOrEvent(message, errorOrEvent) {
    let newMessage = message;
    if (errorOrEvent instanceof ErrorEvent) {
      if (errorOrEvent.filename) {
        newMessage += ` File: ${errorOrEvent.filename}`;
      }

      if (errorOrEvent.lineno || errorOrEvent.colno) {
        newMessage += ` Line: ${errorOrEvent.lineno}:${errorOrEvent.colno}`;
      }

      if (errorOrEvent.message) {
        newMessage += ` Message: ${errorOrEvent.message}`;
      }

      if (errorOrEvent.stack) {
        newMessage += `\nStack: ${errorOrEvent.stack}`;
      }
    } else if (errorOrEvent instanceof Error) {
      if (errorOrEvent.fileName) {
        newMessage += ` File: ${errorOrEvent.fileName}`;
      }

      if (errorOrEvent.lineNumber || errorOrEvent.columnNumber) {
        newMessage += ` Line: ${errorOrEvent.lineNumber}:${errorOrEvent.columnNumber}`;
      }

      if (errorOrEvent.message) {
        newMessage += ` Message: ${errorOrEvent.message}`;
      }

      if (errorOrEvent.stack) {
        newMessage += ` Stack: ${errorOrEvent.stack}`;
      }

      if (errorOrEvent.name) {
        newMessage += ` Name: ${errorOrEvent.name}`;
      }

      if (errorOrEvent.constraint) {
        newMessage += ` Constraint: ${errorOrEvent.constraint}`;
      }
    } else if (errorOrEvent instanceof CloseEvent) {
      if (errorOrEvent.code) {
        newMessage += ` Code: ${errorOrEvent.code}`;
      }

      if (errorOrEvent.reason) {
        newMessage += ` Reason: ${errorOrEvent.reason}`;
      }

      newMessage += ` wasClean: ${errorOrEvent.wasClean}`;
    } else if (errorOrEvent instanceof DOMException) {
      if (errorOrEvent.message) {
        newMessage += ` Message: ${errorOrEvent.message}`;
      }

      if (errorOrEvent.name) {
        newMessage += ` Name: ${errorOrEvent.name}`;
      }
    } else {
      newMessage += errorOrEvent ? errorOrEvent.toString() : '';
    }

    return newMessage;
  }

  error(message, errorOrEvent = null) {
    // Note: errorEvent may be an ErrorEvent, CloseEvent, DOMException, or it may be an Error
    message = this.getMessageFromErrorOrEvent(message, errorOrEvent);

    if (!this._highFrequencyLogs[message]) {
      this._highFrequencyLogs[message] = 1;
    } else {
      this._highFrequencyLogs[message] += 1;
    }

    const shouldReportHighFrequencyLog = isPowerOf2(
      this._highFrequencyLogs[message]
    );

    if (this._instance && shouldReportHighFrequencyLog) {
      this._instance.error(message);
    }
  }

  warn(message, errorEvent = null) {
    message = this.getMessageFromErrorOrEvent(message, errorEvent);
    if (this._instance) this._instance.warn(message);
  }

  log(message) {
    if (this._instance) this._instance.log(message);
  }
}

// Limit reporting logs to their 2^nth occurrence, for n = 0, 1, ..
export const isPowerOf2 = (x) => (x & (x - 1)) === 0;

let logger = new GlobalTracingLogger();

export default logger;

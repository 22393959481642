import * as jsEvent from '../common/jsEvent';
import * as consts from '../worker/common/consts';
import * as jsMediaBuffer from '../inside/JsMediaBuffer';
import util from '../common/util';
import Log from '../common/log';
import globalTracingLogger from '../common/globalTracingLogger';
import PubSub from '../common/pubSub';
import Zoom_Monitor from '../inside/Monitor';
import {
  WORKER_TYPE,
  workerStartTypeToDevToolWorkerNameEnum,
  MAIN_WORKER_COMMAND,
  EncodeDecodeEnum,
  ZOOM_CONNECTION_TYPE,
} from '../common/enums/CommonEnums';
import jsMediaEngineVariables from '../inside/JsMediaEngine_Variables';

const log = Log('sdk.engine');

async function getWorkerUrlBlob(workerType, workerParameters) {
  let responseText;
  switch (workerType) {
    case WORKER_TYPE.VIDEO_ENCODE:
      responseText = jsMediaEngineVariables.videoEncResponseText;
      break;
    case WORKER_TYPE.VIDEO_DECODE:
      responseText = jsMediaEngineVariables.videoDecResponseText;
      break;
    case WORKER_TYPE.AUDIO_ENCODE:
      responseText = jsMediaEngineVariables.audioEncodeResponse;
      break;
    case WORKER_TYPE.AUDIO_DECODE:
      responseText = jsMediaEngineVariables.audioDecodeResponse;
      break;
    case WORKER_TYPE.SHARING_DECODE:
      responseText = jsMediaEngineVariables.sharingDecodeResponse;
      break;
    case WORKER_TYPE.SHARING_ENCODE:
      responseText = jsMediaEngineVariables.sharingEncodeResponse;
      break;
  }

  if (!responseText) {
    responseText = await download(
      workerParameters.workerJsFileUrl,
      workerParameters.integrityHelper
    );
  }

  // This `wasmUrl` line is used to specify to the worker which *.wasm file to download.
  // It is added before a certain line so as to not mess up the source map's mapping.
  const wasmUrlLine =
    'wasmUrl = ' + "'" + workerParameters.workerWasmFileUrl + "';";
  const wasmCodeEndLine = 'self.__wasmCodeDataEndFlag = 1;';
  const wasmCodeEndReg = /self\.__wasmCodeDataEndFlag\s*=\s*1[;|,]/;
  return responseText.replace(wasmCodeEndReg, wasmUrlLine + wasmCodeEndLine);
}

async function XHRWorker(
  workerParameters,
  ready,
  scope,
  workerType,
  sdkInstance
) {
  if (sdkInstance && sdkInstance.isDestroy) {
    Zoom_Monitor.add_monitor('ZIDX', workerType);
    return log(
      `WorkerType:${workerType};The relative SDK instance is destroy, don't start relative worker, avoid multiple same workers. `
    );
  }

  /**
   * worker support name for debug
   * https://developer.mozilla.org/en-US/docs/Web/API/Worker/Worker
   */
  let options = {};
  if (workerStartTypeToDevToolWorkerNameEnum[workerType]) {
    Object.assign(options, {
      name: workerStartTypeToDevToolWorkerNameEnum[workerType],
    });
  }
  let flag = false;
  let worker;

  if (workerType == WORKER_TYPE.AUDIO_ENCODE) {
    if (window['AEW']) {
      worker = window['AEW'];
    }
  } else if (workerType == WORKER_TYPE.AUDIO_DECODE) {
    if (window['ADW']) {
      worker = window['ADW'];
    }
  }

  if (!worker) {
    const blobUrl = window.URL.createObjectURL(
      new Blob([await getWorkerUrlBlob(workerType, workerParameters)])
    );
    worker = new Worker(blobUrl, options);
    worker.addEventListener('error', (error) => {
      if (error.message) {
        if (
          error.message.includes('memory access out') ||
          error.message.includes('table index is out') ||
          error.message.includes('RuntimeError: index out of bounds')
        ) {
          let atnow = performance.now();
          if (atnow - jsMediaEngineVariables.crashLastTime > 30 * 1000) {
            jsMediaEngineVariables.crashCount++;
            jsMediaEngineVariables.crashLastTime = atnow;
            jsMediaEngineVariables.Notify_APPUI(jsEvent.NOTIFY_UI_FAILOVER);
            globalTracingLogger.error(
              `NOTIFY_UI_FAILOVER  ${jsMediaEngineVariables.crashCount}`
            );
          }
        }
      }
      globalTracingLogger.error(
        `Uncaught exception in worker of type ${workerType}`,
        error
      );
    });
    if (workerType === WORKER_TYPE.VIDEO_ENCODE) {
      worker.postMessage({
        command: 'WORKER_BOLB_URL',
        blobUrl,
      });
    }
    if (jsMediaEngineVariables.mediaSDKHandle.is32bitbrowser) {
      if (workerType == WORKER_TYPE.AUDIO_ENCODE) {
        window['AEW'] = worker;
      } else if (workerType == WORKER_TYPE.AUDIO_DECODE) {
        window['ADW'] = worker;
      }
    }
  } else {
    if (workerType == WORKER_TYPE.AUDIO_ENCODE) {
      window['AEWF'] = true;
    } else if (workerType == WORKER_TYPE.AUDIO_DECODE) {
      window['ADWF'] = true;
    }
  }

  if (ready) {
    ready.call(scope, worker);
  }
}

/**
 * @param url {string}
 * @param integrityHelper {IntegrityHelper|null}
 * @returns {Promise<String>}
 */
function download(url, integrityHelper) {
  let integrity = null;
  if (integrityHelper) {
    integrity = integrityHelper.getIntegrity();
  }
  return util.download(url, integrity);
}

/**
 *
 * @param workerParameters
 * @param mgr
 * @param listener
 * @param workerType {WORKER_TYPE}
 * @param sdkInstance
 * @returns {Promise<undefined>}
 * @constructor
 */
function WorkerStart(workerParameters, mgr, listener, workerType, sdkInstance) {
  return new Promise((resolve, reject) => {
    XHRWorker(
      workerParameters,
      function (worker) {
        var ssrc = jsMediaEngineVariables.SPECIAL_ID;
        if (mgr) {
          Zoom_Monitor.add_monitor('WAM' + workerType);
          mgr.Add(ssrc, worker);
        }
        worker.onmessage = (e) => {
          listener(e);
        };
        resolve();
      },
      this,
      workerType,
      sdkInstance
    );
  });
}

/**
 *
 * @param workerType {WORKER_TYPE}
 */
function allWorkersListener(workerType, e) {
  let message = e.data;

  [allWorkersListener_AES_IV].forEach((fn) => {
    try {
      fn.call(null, workerType, message);
    } catch (e) {
      globalTracingLogger.error('Error from allWorkersListener_AES_IV', e);
    }
  });
}

/**
 *
 * @param workerType {WORKER_TYPE}
 */
function allWorkersListener_AES_IV(workerType, message) {
  let workerTypeList = [
    WORKER_TYPE.AUDIO_ENCODE,
    WORKER_TYPE.VIDEO_ENCODE,
    WORKER_TYPE.SHARING_ENCODE,
  ];

  if (
    workerTypeList.indexOf(workerType) !== -1 &&
    message.status === jsEvent.AES_GCM_IV_CALLBACK_FROM_WASM
  ) {
    log('allWorkersListener_AES_IV', workerType, message);

    // if modify {@link util.buffer2stringSplitByComma),
    // must also modify {@link util.stringSplitByComma2Buffer}
    jsMediaEngineVariables.Notify_APPUI_SAFE(jsEvent.AES_GCM_IV_RESPONSE, {
      workerType,
      iv: util.buffer2stringSplitByComma(message.data),
    });
  }
}

export function setUserNodeListToWorker(userNodeList) {
  let oldList = jsMediaEngineVariables.userNodeList || [];
  let sourceList = userNodeList.concat(oldList);

  jsMediaEngineVariables.userNodeList = util.removeDuplicates(
    sourceList,
    (a, b) => {
      return a.userid !== b.userid;
    }
  );

  let workerTypeList = [
    WORKER_TYPE.AUDIO_ENCODE,
    WORKER_TYPE.AUDIO_DECODE,
    WORKER_TYPE.VIDEO_ENCODE,
    WORKER_TYPE.VIDEO_DECODE,
    WORKER_TYPE.SHARING_ENCODE,
    WORKER_TYPE.SHARING_DECODE,
  ];

  workerTypeList.forEach(async (workerType) => {
    let mediaInitIns;
    switch (workerType) {
      case WORKER_TYPE.AUDIO_ENCODE:
        mediaInitIns = jsMediaEngineVariables.audioEncodeInitInstance;
        break;
      case WORKER_TYPE.AUDIO_DECODE:
        mediaInitIns = jsMediaEngineVariables.audioDecInitInstance;
        break;
      case WORKER_TYPE.VIDEO_ENCODE:
        mediaInitIns = jsMediaEngineVariables.videoInitInstance;
        break;
      case WORKER_TYPE.VIDEO_DECODE:
        mediaInitIns = jsMediaEngineVariables.videoDecInitInstance;
        break;
      case WORKER_TYPE.SHARING_ENCODE:
        mediaInitIns = jsMediaEngineVariables.sharingEncInitInstance;
        break;
      case WORKER_TYPE.SHARING_DECODE:
        mediaInitIns = jsMediaEngineVariables.sharingDecInitInstance;
        break;
    }

    try {
      await mediaInitIns.waitforInitSuccess();
      log('setUserNodeListToWorker init success', workerType);

      pushMessageToWorker(
        workerType,
        {
          userNodeList: jsMediaEngineVariables.userNodeList,
        },
        MAIN_WORKER_COMMAND.SET_USER_NODE_LIST,
        false,
        true
      );
    } catch (e) {
      globalTracingLogger.error(
        'Error when sending user node list to worker',
        e
      );
    }
  });
}

export function UpdateAudioPlayStatus(status) {
  jsMediaEngineVariables.isAudioPlayWork = status;
}

export async function UpdateVideoPlayStatus(status) {
  jsMediaEngineVariables.isVideoPlayWork = status;
  await pushMessageToWorker(
    WORKER_TYPE.VIDEO_DECODE,
    {
      isVideoPlayWork: status,
    },
    'VideoPlayStatus',
    false,
    true
  );
}

export function UpdateSharingPlayStatus(status) {
  jsMediaEngineVariables.isSharingPlayWork = status;
}

function Float32Concat(first, second) {
  var firstLength = 0;

  if (first !== null) {
    firstLength = first.length;
    var result = new Float32Array(firstLength + second.length);
    result.set(first);
    result.set(second, firstLength);
    return result;
  }
  return second;
}

/** preserve messages and resend when time is ok */
export const preserveMessageController = (function () {
  const direction = {
    SDK_TO_WORKER: 'SDK_TO_WORKER',
    SDK_TO_WORKER_WAIT_OK: 'SDK_TO_WORKER_WAIT_OK',
    SDK_TO_APP: 'SDK_TO_APP',
    SDK_TO_SDK: 'SDK_TO_SDK',
  };
  return {
    nameMap: {
      INIT_VIDEO_ENCODE_SUCCESS: 'INIT_VIDEO_ENCODE_SUCCESS',
      INIT_VIDEO_DECODE_SUCCESS: 'INIT_VIDEO_DECODE_SUCCESS',
      CREATE_VIDEO_ENCODE_HANDLE_SUCCESS: 'CREATE_VIDEO_ENCODE_HANDLE_SUCCESS',
      CREATE_VIDEO_DECODE_HANDLE_SUCCESS: 'CREATE_VIDEO_DECODE_HANDLE_SUCCESS',
      CREATE_AUDIO_ENCODE_HANDLE_SUCCESS: 'CREATE_AUDIO_ENCODE_HANDLE_SUCCESS',
      CREATE_AUDIO_DECODE_HANDLE_SUCCESS: 'CREATE_AUDIO_DECODE_HANDLE_SUCCESS',
    },
    map: new Map(),
    direction,
    clear() {
      this.map.clear();
    },
    subscribeMessage(name) {
      const targetNameMessager = this.map.get(name);
      if (targetNameMessager) {
        targetNameMessager.forEach((item) => {
          const { direction: messageDirection, messageParams, handler } = item;
          if (messageDirection === direction.SDK_TO_APP) {
            jsMediaEngineVariables.Notify_APPUI(...messageParams);
          } else if (messageDirection === direction.SDK_TO_WORKER_WAIT_OK) {
            pushMessageToWorker(...messageParams);
          } else if (messageDirection === direction.SDK_TO_WORKER) {
            const [workerType, resetMessageParams] = messageParams;
            const handle = getHandle(workerType);
            if (handle) {
              handle.postMessage(resetMessageParams);
            }
          } else if (messageDirection === direction.SDK_TO_SDK) {
            handler && handler(messageParams);
          }
        });
        this.map.delete(name);
      }
    },
    enqueueMessage({ name, messageParams, direction, ...rest }) {
      if (!this.map.has(name)) {
        this.map.set(name, []);
      }
      this.map.get(name).push({
        direction,
        messageParams: messageParams || [],
        ...rest,
      });
    },
  };
})();

export const previewController = (function () {
  return {
    /**
     * @description preview -> in-meeting media sdk need to clear some status
     */
    resetAudioEncode: (isPreviewMode) => {
      if (!isPreviewMode && jsMediaEngineVariables.isPreviewMode.audioEncode) {
        jsMediaEngineVariables.isAudioEncodePostStart = false;
      }
      jsMediaEngineVariables.isPreviewMode.audioEncode = !!isPreviewMode;
    },
    resetAudioDecode: (isPreviewMode) => {
      if (!isPreviewMode && jsMediaEngineVariables.isPreviewMode.audioDecode) {
        jsMediaEngineVariables.isAudioDecodePostStart = false;
      }
      jsMediaEngineVariables.isPreviewMode.audioDecode = !!isPreviewMode;
    },
    resetVideoEncode: (isPreviewMode) => {
      if (!isPreviewMode && jsMediaEngineVariables.isPreviewMode.videoEncode) {
        jsMediaEngineVariables.isVideoEncodePostStart = false;
      }
      jsMediaEngineVariables.isPreviewMode.videoEncode = !!isPreviewMode;
    },
    resetVideoDecode: (isPreviewMode) => {
      if (!isPreviewMode && jsMediaEngineVariables.isPreviewMode.videoDecode) {
        jsMediaEngineVariables.isVideoDecodePostStart = false;
      }
      jsMediaEngineVariables.isPreviewMode.videoDecode = !!isPreviewMode;
    },
  };
})();

/**
 *
 * @param sdkInstance
 * @returns {Promise<void>}
 */
export async function initVideoEncode(workerParameters, sdkInstance) {
  if (!jsMediaEngineVariables.localVideoEncMGR) {
    jsMediaEngineVariables.localVideoEncMGR = new jsMediaBuffer.VideoMGR({
      sessionid: sdkInstance._id,
    });
  }

  AssertSessionIdEqualSdkId(
    WORKER_TYPE.VIDEO_DECODE,
    jsMediaEngineVariables.localVideoEncMGR.sessionid,
    sdkInstance._id
  );

  jsMediaEngineVariables.videoEncWorkerPath = workerParameters.workerJsFileUrl;

  let data = await download(
    workerParameters.workerJsFileUrl,
    workerParameters.integrityHelper
  );
  jsMediaEngineVariables.videoEncResponseText = data;
  if (!AssertMediaSdkNotDestory(sdkInstance)) {
    return false;
  }
  await Add_Video_Encode_Thread(0, null, sdkInstance, workerParameters);
  if (!AssertMediaSdkNotDestory(sdkInstance, 'Add_Video_Encode_Thread')) {
    globalTracingLogger.error('Add_Video_Encode_Thread Worker Not Controled');
    return false;
  }
  preserveMessageController.subscribeMessage(
    preserveMessageController.nameMap.CREATE_VIDEO_ENCODE_HANDLE_SUCCESS
  );
  await Video_Encode_Post_message(sdkInstance);
}

function AssertSessionIdEqualSdkId(type, sessionid, sdkid) {
  let ret = sessionid == sdkid ? true : false;
  if (!ret) {
    globalTracingLogger.error(
      `mediasdkInstance id ${sdkid} not equal  media type ${type} id ${sessionid}`
    );
  }
  return ret;
}

export function AssertMediaSdkNotDestory(sdkInstance, from = '') {
  if (sdkInstance.isDestroy) {
    globalTracingLogger.log(
      `mediasdkInstance id ${sdkInstance._id} is Destoryed from ${from}`
    );
  }
  return !sdkInstance.isDestroy;
}

/**
 * @param sdkInstance
 * @returns {Promise<void>}
 */
export async function initVideoDecode(workerParameters, sdkInstance) {
  if (!jsMediaEngineVariables.localVideoDecMGR) {
    jsMediaEngineVariables.localVideoDecMGR = new jsMediaBuffer.VideoMGR({
      sessionid: sdkInstance._id,
    });
  }
  AssertSessionIdEqualSdkId(
    WORKER_TYPE.VIDEO_DECODE,
    jsMediaEngineVariables.localVideoDecMGR.sessionid,
    sdkInstance._id
  );

  jsMediaEngineVariables.videoDecWorkerPath = workerParameters.workerJsFileUrl;
  let data = await download(
    workerParameters.workerJsFileUrl,
    workerParameters.integrityHelper
  );
  if (!AssertMediaSdkNotDestory(sdkInstance)) {
    return false;
  }

  jsMediaEngineVariables.videoDecResponseText = data;
  await Add_Video_Decode_Thread(0, null, sdkInstance, workerParameters);

  if (!AssertMediaSdkNotDestory(sdkInstance, 'Add_Video_Decode_Thread')) {
    globalTracingLogger.error('Add_Video_Decode_Thread Worker Not Controled');
    return false;
  }
  preserveMessageController.subscribeMessage(
    preserveMessageController.nameMap.CREATE_VIDEO_DECODE_HANDLE_SUCCESS
  );
  await Video_Decode_Post_message(sdkInstance);
}

/**
 *
 * @param sdkInstance
 * @returns {Promise<void>}
 */
export async function initAudioEncode(workerParameters, sdkInstance) {
  await initAudioSharedBuffer();

  if (!jsMediaEngineVariables.localAudioEncMGR) {
    jsMediaEngineVariables.localAudioEncMGR = new jsMediaBuffer.AudioMGR({
      sessionid: sdkInstance._id,
    });
  }

  AssertSessionIdEqualSdkId(
    WORKER_TYPE.AUDIO_ENCODE,
    jsMediaEngineVariables.localAudioEncMGR.sessionid,
    sdkInstance._id
  );

  jsMediaEngineVariables.audioEncWorkerPath = workerParameters.workerJsFileUrl;
  jsMediaEngineVariables.audioEncodeResponse = await download(
    workerParameters.workerJsFileUrl,
    workerParameters.integrityHelper
  );
  if (!AssertMediaSdkNotDestory(sdkInstance)) {
    return false;
  }
  await Add_Audio_Encode_Thread(0, sdkInstance, workerParameters);
  if (!AssertMediaSdkNotDestory(sdkInstance, 'Add_Audio_Encode_Thread')) {
    globalTracingLogger.error('Add_Audio_Encode_Thread Worker Not Controled');
    return false;
  }
  preserveMessageController.subscribeMessage(
    preserveMessageController.nameMap.CREATE_AUDIO_ENCODE_HANDLE_SUCCESS
  );
  await Audio_Encode_Post_message(sdkInstance);
}

/**
 * @param sdkInstance
 * @returns {Promise<void>}
 */
export async function initAudioDecode(workerParameters, sdkInstance) {
  await initAudioSharedBuffer();

  if (!jsMediaEngineVariables.localAudioDecMGR) {
    jsMediaEngineVariables.localAudioDecMGR = new jsMediaBuffer.AudioMGR({
      sessionid: sdkInstance._id,
    });
  }
  AssertSessionIdEqualSdkId(
    WORKER_TYPE.AUDIO_DECODE,
    jsMediaEngineVariables.localAudioDecMGR.sessionid,
    sdkInstance._id
  );

  jsMediaEngineVariables.audioDecWorkerPath = workerParameters.workerJsFileUrl;
  jsMediaEngineVariables.audioDecodeResponse = await download(
    workerParameters.workerJsFileUrl,
    workerParameters.integrityHelper
  );
  if (typeof jsMediaEngineVariables.audioDecodeResponse === 'string') {
    Zoom_Monitor.add_monitor(
      'DAFL' + jsMediaEngineVariables.audioDecodeResponse.length
    );
  } else {
    Zoom_Monitor.add_monitor('DAFF');
  }
  if (!AssertMediaSdkNotDestory(sdkInstance)) {
    return false;
  }
  await Add_Audio_Decode_Thread(0, null, sdkInstance, workerParameters);

  if (!AssertMediaSdkNotDestory(sdkInstance, 'Add_Audio_Decode_Thread')) {
    globalTracingLogger.error('Add_Audio_Decode_Thread Worker Not Controled');
    return false;
  }
  preserveMessageController.subscribeMessage(
    preserveMessageController.nameMap.CREATE_AUDIO_DECODE_HANDLE_SUCCESS
  );
  Zoom_Monitor.add_monitor('ADTS');
  await Audio_Decode_Post_message(sdkInstance);
}

export async function initSharingDecode(workerParameters, sdkInstance) {
  if (!jsMediaEngineVariables.localSharingDecMGR) {
    jsMediaEngineVariables.localSharingDecMGR = new jsMediaBuffer.SharingMGR({
      sessionid: sdkInstance._id,
    });
  }
  if (!jsMediaEngineVariables.localMouseDecMGR) {
    jsMediaEngineVariables.localMouseDecMGR = new jsMediaBuffer.SharingMGR({
      sessionid: sdkInstance._id,
    });
  }

  AssertSessionIdEqualSdkId(
    WORKER_TYPE.SHARING_DECODE,
    jsMediaEngineVariables.localSharingDecMGR.sessionid,
    sdkInstance._id
  );

  jsMediaEngineVariables.sharingDecWorkerPath =
    workerParameters.workerJsFileUrl;
  jsMediaEngineVariables.sharingDecodeResponse = await download(
    workerParameters.workerJsFileUrl,
    workerParameters.integrityHelper
  );
  if (!AssertMediaSdkNotDestory(sdkInstance)) {
    return false;
  }
  await Add_Sharing_Decode_Thread(sdkInstance, workerParameters);
  if (!AssertMediaSdkNotDestory(sdkInstance, 'Add_Sharing_Decode_Thread')) {
    globalTracingLogger.error('Add_Sharing_Decode_Thread Worker Not Controled');
    return false;
  }
  await Sharing_Decode_Post_message(sdkInstance);
}

export async function initSharingEncode(workerParameters, sdkInstance) {
  if (!jsMediaEngineVariables.localSharingEncMGR) {
    jsMediaEngineVariables.localSharingEncMGR = new jsMediaBuffer.SharingMGR({
      sessionid: sdkInstance._id,
    });
  }
  AssertSessionIdEqualSdkId(
    WORKER_TYPE.SHARING_ENCODE,
    jsMediaEngineVariables.localSharingEncMGR.sessionid,
    sdkInstance._id
  );
  jsMediaEngineVariables.sharingEncWorkerPath =
    workerParameters.workerJsFileUrl;
  jsMediaEngineVariables.sharingEncodeResponse = await download(
    workerParameters.workerJsFileUrl,
    workerParameters.integrityHelper
  );
  if (!AssertMediaSdkNotDestory(sdkInstance)) {
    return false;
  }
  await Add_Sharing_Encode_Thread(sdkInstance, workerParameters);
  if (!AssertMediaSdkNotDestory(sdkInstance, 'Add_Sharing_Encode_Thread')) {
    globalTracingLogger.error('Add_Sharing_Encode_Thread Worker Not Controled');
    return false;
  }
  await Sharing_Encode_Post_message(sdkInstance);
}

export async function Sharing_Decode_Post_message(sdkInstance) {
  await jsMediaEngineVariables.sharingDecInitInstance.wasmSuccessPromise;

  if (!AssertMediaSdkNotDestory(sdkInstance)) {
    return false;
  }
  var handle = jsMediaEngineVariables.localSharingDecMGR.Get(
    jsMediaEngineVariables.SPECIAL_ID
  );
  if (handle && !jsMediaEngineVariables.isSharingDecodePostStart) {
    handle.postMessage({
      command: 'startMedia',
      _id: sdkInstance._id,
      websocket_ip_address: jsMediaEngineVariables.Sharing_WebSocket_Ip_Address,
      confId: jsMediaEngineVariables.localSharingPara.userid,
      confKey: '',
      logon: jsMediaEngineVariables.localSharingPara.logon,
      meetingid: jsMediaEngineVariables.localSharingPara.meetingid,
      meetingnumb: jsMediaEngineVariables.localSharingPara.meetingnumb,
      multiThreadNum: 1,
      enableReplaceCanvasWhenContextLost:
        jsMediaEngineVariables.enableReplaceCanvasWhenContextLost,
      graphicalname: util.graphicName,
      vendorname: util.graphicvendorname,
    });
    jsMediaEngineVariables.isSharingDecodePostStart = true;
  }
}

export async function Sharing_Encode_Post_message(sdkInstance) {
  await jsMediaEngineVariables.sharingEncInitInstance.wasmSuccessPromise;

  if (!AssertMediaSdkNotDestory(sdkInstance)) {
    return false;
  }
  var handle = jsMediaEngineVariables.localSharingEncMGR.Get(
    jsMediaEngineVariables.SPECIAL_ID
  );
  if (handle && !jsMediaEngineVariables.isSharingEncodePostStart) {
    //By now, We DONT support multi-thread for sharing encode
    let multithreadnumb =
      false && jsMediaEngineVariables.localSharingPara.isSupportMultiThread
        ? 4
        : 1;
    Zoom_Monitor.add_monitor2('STN' + multithreadnumb);
    let iv = null;
    if (jsMediaEngineVariables.ivObj) {
      iv = jsMediaEngineVariables.ivObj[WORKER_TYPE.SHARING_ENCODE];
      // if modify {@link util.stringSplitByComma2Buffer),
      // must also modify {@link util.buffer2stringSplitByComma}
      iv = util.stringSplitByComma2Buffer(iv);
    }
    handle.postMessage({
      command: 'startMedia',
      _id: sdkInstance._id,
      encode: true,
      websocket_ip_address: jsMediaEngineVariables.Sharing_WebSocket_Ip_Address,
      confId: jsMediaEngineVariables.localSharingPara.userid,
      confKey: '',
      logon: jsMediaEngineVariables.localSharingPara.logon,
      isChromeOrEdge: util.browser.isChrome,
      meetingid: jsMediaEngineVariables.localSharingPara.meetingid,
      meetingnumb: jsMediaEngineVariables.localSharingPara.meetingnumb,
      multiThreadNum: 1,
      iv,
      uplimit: jsMediaEngineVariables.uplimit,
      graphicalname: util.graphicName,
      vendorname: util.graphicvendorname,
    });
    jsMediaEngineVariables.isSharingEncodePostStart = true;
  }
}

function isAllowedSharingSSRC(data) {
  try {
    let ssrcFromPDU = data.ssrcFromPDU;
    if (ssrcFromPDU === null || ssrcFromPDU === undefined) return true;
    let val = 0xfffffc00;
    return (
      (jsMediaEngineVariables.localSharingPara.userid & val) !==
      (ssrcFromPDU & val)
    );
  } catch (ex) {
    return true;
  }
}

function SharingDec_Listener(e) {
  var message = e.data;
  try {
    if (message.status === jsEvent.Sharing_Data) {
      // if message.data.ssrcFromPDU is myself, drop these frames, don't show myself sharing image
      let isAllowed = isAllowedSharingSSRC(message.data);
      if (isAllowed) {
        Put_Sharing_Data_Buffer(message.data);
      }
    } else if (message.status === jsEvent.Sharing_Width_And_Height_Info) {
      PubSub.publish(jsEvent.SHARING_PARAM_INFO_FROM_SOCKET, {
        body: {
          width: message.logicWidth,
          height: message.logicHeight,
          logicWidth: message.logicWidth,
          logicHeight: message.logicHeight,
        },
      });
      jsMediaEngineVariables.Notify_APPUI(jsEvent.SHARING_PARA, {
        body: {
          width: message.logicWidth,
          height: message.logicHeight,
          logicWidth: message.logicWidth,
          logicHeight: message.logicHeight,
        },
      });
    } else if (message.status === jsEvent.FIRST_SHARING_FRAME_FOR_MOBILE) {
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.FIRST_IOS_FRAME,
        message.ssrc
      );
    } else if (message.status === jsEvent.SHARING_RENDER_MONITOR_LOG) {
      Zoom_Monitor.add_monitor2(message.data);
    } else if (message.status === jsEvent.SHARING_FIRST_DECODE_FRAME_RECEIVED) {
      Zoom_Monitor.add_monitor('FSF');
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.SHARING_FIRST_DECODE_FRAME_RECEIVED_SSRC,
        {
          ssrc: message.ssrc,
        }
      );
    } else if (message.status === jsEvent.SHARING_DATA_VIDEO_MODE) {
      Put_Sharing_Frame_Buffer(
        message.sharing_ssrc,
        message.data,
        message.sharing_timestamp,
        message.sharing_width,
        message.sharing_height,
        message.rendering_x,
        message.rendering_y,
        message.rendering_w,
        message.rendering_h,
        message.logic_w,
        message.logic_h,
        message.yuv_limited,
        message.isFromMainSession
      );
    } else if (message.status === jsEvent.MOUSE_DATA_VIDEO_MODE) {
      Put_Mouse_Data_Buffer(
        message.mouse_ssrc,
        message.data,
        message.mouse_timestamp,
        message.mouse_width,
        message.mouse_height,
        message.mouse_x,
        message.mouse_y,
        message.mLogic_w,
        message.mLogic_h,
        message.sync_id
      );
    } else if (message.status === jsEvent.SHARING_DECODE_MESSAGE) {
      jsMediaEngineVariables.Notify_APPUI(jsEvent.SHARING_DECODE_MAX_SIZE, {
        ssrc: message.ssrc,
        /** size is not meaningful */
        size: message.size,
        /** size -> fps 0: 1fps; 1: 5fps; 2: 24fps */
        fps: message.size,
      });
    } else if (message.status === jsEvent.VIDEOSHARE_QOS_DATA) {
      const messageData = {
        encoding: false,
        ...message.data,
      };
      jsMediaEngineVariables.Notify_APPUI_SAFE(
        jsEvent.VIDEOSHARE_QOS_DATA,
        messageData
      );
    } else if (message.status === jsEvent.Sharing_Dec_WASM_OK) {
      Zoom_Monitor.add_monitor('SDWS');
      jsMediaEngineVariables.isSharingDecodeWASMOK = true;
      jsMediaEngineVariables.sharingDecInitInstance.setWasmSuccess();
      //jsMediaEngineVariables.sharingDecInitInstance.setSocketSuccess();
    } else if (message.status === jsEvent.Sharing_Dec_WASM_FAILED) {
      Zoom_Monitor.add_monitor('SDWF', message.data);
      jsMediaEngineVariables.sharingDecInitInstance.setWasmSuccess(false);
    } else if (message.status === jsEvent.Sharing_Handle_OK) {
      Zoom_Monitor.add_monitor('SDHS');
      jsMediaEngineVariables.sharingDecInitInstance.setHanderSuccess();
    } else if (message.status === jsEvent.Sharing_Handle_FAILED) {
      Zoom_Monitor.add_monitor('SHHF');
      jsMediaEngineVariables.sharingDecInitInstance.setHanderSuccess(false);
    } else if (message.status === jsEvent.Sharing_Dec_WebSocket_OK) {
      Zoom_Monitor.add_monitor('SDSS');
      jsMediaEngineVariables.sharingDecInitInstance.setSocketSuccess();
    } else if (message.status === jsEvent.Sharing_Dec_WebSocket_FAILED) {
      Zoom_Monitor.add_monitor('SDSF');
      if (jsMediaEngineVariables.sharingDecInitInstance.isSocketInitSuccess()) {
        jsMediaEngineVariables.Notify_APPUI_SAFE(
          jsEvent.VIDEO_WEBSOCKET_BROKEN,
          null
        );
      }
      jsMediaEngineVariables.sharingDecInitInstance.setSocketSuccess(false);
    } else if (message.status == jsEvent.MONITOR_MESSAGE) {
      sendMonitorLog(message.data);
      return;
    } else if (message.status === jsEvent.DOWNLOAD_WASM_FROM_MAIN_THREAD) {
      let handle = jsMediaEngineVariables.localSharingDecMGR.map.get(
        jsMediaEngineVariables.SPECIAL_ID
      );
      downloadWASMAndPostToWorker(
        message.url,
        handle,
        WORKER_TYPE.SHARING_DECODE
      );
    } else if (message.status == jsEvent.APP_TROUBLESHOOTING_INFO) {
      jsMediaEngineVariables.monitorSharingDecodeAPPInfo = message;
      sendMonitorLog(jsMediaEngineVariables.monitorSharingDecodeAPPInfo.data);
      jsMediaEngineVariables.monitorSharingDecodeAPPInfo = null;
    } else if (message.status == jsEvent.WCL_TROUBLESHOOTING_INFO) {
      Zoom_Monitor.add_monitor('SD' + message.data);
    } else if (message.status == consts.GLOBAL_TRACING_LOG) {
      globalTracingLogger.error(
        `Error from sharing decode worker: ${message.errorMessage}`,
        message.errorEvent
      );
    } else if (message.status == jsEvent.MULTIVIEW_WEBGL_CONTEXT_LOST) {
      jsMediaEngineVariables.Notify_APPUI(jsEvent.WEBGL_LOST_IN_MULTI_VIEW, {
        canvasId: message.canvasId,
        replaceCanvas: !!message.replaceCanvas,
      });
    } else if (message.status == jsEvent.SHARING_DECODE_MONITOR_MESSAGE) {
      jsMediaEngineVariables.monitorDecodeSharing = message;
    } else if (message.status == jsEvent.VIDEO_ENCODED_DATA) {
      let data = message.data;
      PubSub.triggerSync(jsEvent.VIDEO_DATA_FROM_WORKER, data);
    } else if (message.status == 'SHARING_DECODE_PTR') {
      PubSub.triggerSync('SHARING_DECODE_PTR', message);
    } else if (message.status == 'WFMO') {
      SendWasmMemory(WORKER_TYPE.SHARING_DECODE);
    } else {
      allWorkersListener(WORKER_TYPE.SHARING_DECODE, e);
    }
  } catch (ex) {
    log.error(ex);
  }
}

function SharingEnc_Listener(e) {
  var message = e.data;
  try {
    if (message.status === jsEvent.Sharing_Data) {
      Put_Sharing_Data_Buffer(message.data);
    } else if (message.status === jsEvent.SHARING_GET_IMAGE_DATA_WRONG) {
      Zoom_Monitor.add_monitor('GIDF');
    } else if (message.status === jsEvent.Sharing_Dec_WASM_OK) {
      Zoom_Monitor.add_monitor('SEWS');
      jsMediaEngineVariables.isSharingEncodeWASMOK = true;
      jsMediaEngineVariables.sharingEncInitInstance.setWasmSuccess();
    } else if (message.status === jsEvent.Sharing_Dec_WASM_FAILED) {
      Zoom_Monitor.add_monitor('SEWF', message.data);
      jsMediaEngineVariables.sharingEncInitInstance.setWasmSuccess(false);
    } else if (message.status === jsEvent.Sharing_Handle_OK) {
      Zoom_Monitor.add_monitor('SEHS');
      jsMediaEngineVariables.sharingEncInitInstance.setHanderSuccess();
    } else if (message.status === jsEvent.Sharing_Handle_FAILED) {
      Zoom_Monitor.add_monitor('SEHF');
      jsMediaEngineVariables.sharingEncInitInstance.setHanderSuccess(false);
    } else if (message.status === jsEvent.Sharing_Dec_WebSocket_OK) {
      Zoom_Monitor.add_monitor('SESS');
      jsMediaEngineVariables.sharingEncInitInstance.setSocketSuccess();
    } else if (message.status === jsEvent.Sharing_Dec_WebSocket_FAILED) {
      Zoom_Monitor.add_monitor('SESF', message.data);
      if (jsMediaEngineVariables.sharingEncInitInstance.isSocketInitSuccess()) {
        jsMediaEngineVariables.Notify_APPUI_SAFE(
          jsEvent.VIDEO_WEBSOCKET_BROKEN,
          null
        );
      }
      jsMediaEngineVariables.sharingEncInitInstance.setSocketSuccess(false);
    } else if (
      message.status == jsEvent.SHARING_CAPTURE_FRAME_COUNT_STATISTIC
    ) {
      Zoom_Monitor.add_monitor2('SCFOK');
    } else if (message.status == jsEvent.UNSUPPORTED_SHARING_FORMAT) {
      Zoom_Monitor.add_monitor('SCFF' + message.format);
    } else if (message.status == jsEvent.Video_Capture_Tick) {
      if (jsMediaEngineVariables.mediaSDKHandle.isSupportVideoTrackReader) {
        jsMediaEngineVariables.mediaSDKHandle.canISendNextSharingFrame = true;
        jsMediaEngineVariables.mediaSDKHandle.Process_Sharing();
      } else {
        jsMediaEngineVariables.mediaSDKHandle.Process_Sharing();
      }
    } else if (message.status == jsEvent.WhiteBoard_Video_Capture_Tick) {
      jsMediaEngineVariables.mediaSDKHandle.Process_Sharing();
    } else if (
      message.status ===
      jsEvent.CURRENT_DESKTOP_SHARING_WIDTH_HEIGHT_FROM_WORKER
    ) {
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.CURRENT_DESKTOP_SHARING_WIDTH_HEIGHT,
        {
          width: message.width,
          height: message.height,
        }
      );
    } else if (message.status == jsEvent.MONITOR_MESSAGE) {
      sendMonitorLog(message.data);
      return;
    } else if (message.status == jsEvent.DOWNLOAD_WASM_FROM_MAIN_THREAD) {
      let handle = jsMediaEngineVariables.localSharingEncMGR.map.get(
        jsMediaEngineVariables.SPECIAL_ID
      );
      downloadWASMAndPostToWorker(message.url, handle);
    } else if (message.status === jsEvent.VIDEOSHARE_QOS_DATA) {
      const messageData = {
        encoding: true,
        ...message.data,
      };
      jsMediaEngineVariables.Notify_APPUI_SAFE(
        jsEvent.VIDEOSHARE_QOS_DATA,
        messageData
      );
    } else if (message.status == jsEvent.APP_TROUBLESHOOTING_INFO) {
      jsMediaEngineVariables.monitorSharingEncodeAPPInfo = message;
      sendMonitorLog(jsMediaEngineVariables.monitorSharingEncodeAPPInfo.data);
      jsMediaEngineVariables.monitorSharingEncodeAPPInfo = null;
    } else if (message.status == jsEvent.WCL_TROUBLESHOOTING_INFO) {
      Zoom_Monitor.add_monitor('SE' + message.data);
    } else if (message.status == jsEvent.MULTIVIEW_WEBGL_CONTEXT_LOST) {
      jsMediaEngineVariables.Notify_APPUI(jsEvent.WEBGL_LOST_IN_MULTI_VIEW, {
        canvasId: message.canvasId,
        replaceCanvas: !!message.replaceCanvas,
      });
    } else if (message.status == consts.GLOBAL_TRACING_LOG) {
      globalTracingLogger.error(
        `Error from sharing encode worker: ${message.errorMessage}`,
        message.errorEvent
      );
    } else if (message.status == jsEvent.WHITEBOARD_WORKER_MESSAGE) {
      jsMediaEngineVariables.Notify_APPUI(jsEvent.WB_MESSAGE, {
        data: message.data,
      });
    } else if (message.status == 'WFMO') {
      SendWasmMemory(WORKER_TYPE.SHARING_ENCODE);
    } else {
      allWorkersListener(WORKER_TYPE.SHARING_ENCODE, e);
    }
  } catch (ex) {
    log.error(ex);
  }
}

function VideoDec_Listener(e) {
  var message = e.data;
  var flag = true;
  try {
    //todo drop ratio event of decode
    if (message.status === jsEvent.VIDEO_DATA_DROP_RATIO) {
      //todo Notify_APPUI
    } else if (message.status === 'NewActiveSpeakerFirstframeCallback') {
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.NEW_ACTIVE_SPEAKER_FIRST_FRAME_CALLBACK,
        { ssrc: message.ssrc }
      );
    } else if (message.status === jsEvent.VIDEO_RESOLUTION_UPDATE) {
      jsMediaEngineVariables.Notify_APPUI(jsEvent.CURRENT_VIDEO_RESOLUTION, {
        width: message.width,
        height: message.height,
      });
    } else if (message.status === jsEvent.VIDEO_ENCODED_DATA) {
      flag = false;
      let data = message.data;
      // video/audio data to websocket do not use async action. because async action will be very slow when some browsers (like Chrome) run in background mode.
      PubSub.triggerSync(jsEvent.VIDEO_DATA_FROM_WORKER, data);
    } else if (
      message.status === jsEvent.WORKER_MAIN_VIDEO_DECODE_RINGBUFFER_TICK
    ) {
      let consumer =
        jsMediaEngineVariables.mediaSDKHandle
          .videoDecodeFrameBackSABRingBufferConsumer;
      if (consumer) {
        consumer.consumeAll(
          jsMediaEngineVariables.mediaSDKHandle
            .bVideoDecodeFrameBackSABRingBufferConsumeCopyData
        );
      }
    } else if (message.status === jsEvent.VIDEO_RENDER_MONITOR_LOG) {
      Zoom_Monitor.add_monitor2(message.data);
    } else if (message.status == jsEvent.MULTIVIEW_WEBGL_CONTEXT_LOST) {
      Zoom_Monitor.add_monitor('MWGLF');
      jsMediaEngineVariables.Notify_APPUI(jsEvent.WEBGL_LOST_IN_MULTI_VIEW, {
        canvasId: message.canvasId,
        replaceCanvas: !!message.replaceCanvas,
      });
    } else if (message.status == jsEvent.WEBGL_CONTEXT_CREATE_FAILED) {
      Zoom_Monitor.add_monitor('MWCGLF');
    } else if (message.status === jsEvent.VIDEO_DROP_RATIO_FROM_WCL) {
      let data = message.data;
      // video/audio data to websocket do not use async action. because async action will be very slow when some browsers (like Chrome) run in background mode.
      PubSub.triggerSync(jsEvent.VIDEO_DATA_FROM_WORKER, data);
    } else if (message.status === 0) {
      Put_Video_Frame_Buffer(
        message.video_ssrc,
        message.data,
        message.video_timestamp,
        message.video_width,
        message.video_height,
        message.rendering_x,
        message.rendering_y,
        message.rendering_w,
        message.rendering_h,
        message.rotation,
        message.yuv_limited
      );
    } else if (message.status === jsEvent.Video_Dec_WASM_OK) {
      Zoom_Monitor.add_monitor('VDWS');
      jsMediaEngineVariables.isVideoDecodeWASMOK = true;
      jsMediaEngineVariables.videoDecInitInstance.setWasmSuccess();
      jsMediaEngineVariables.videoDecInitInstance.setSocketSuccess();
      if (jsMediaEngineVariables.isPreviewMode.videoDecode) {
        jsMediaEngineVariables.Notify_APPUI_SAFE(
          jsEvent.PREVIEW_INIT_VIDEO_DECODE_SUCCESS
        );
      }
    } else if (message.status === jsEvent.DECODE_MESSAGE) {
      /**
       * Google Nest devices don't perform well, so don't decode more than 360P
       * 1 =>180p
       * 2 =>360p
       * 3 =>720p
       */
      let size = message.size;
      if (util.isGoogleNestChrome()) {
        size = size <= 2 ? size : 2;
      }
      jsMediaEngineVariables.Notify_APPUI(jsEvent.VIDEO_DECODE_MAX_SIZE, {
        ssrc: message.ssrc,
        size: size,
      });
      Zoom_Monitor.add_monitor('VDMS:' + size);
    } else if (message.status === jsEvent.Video_Dec_WASM_FAILED) {
      Zoom_Monitor.add_monitor('VDWF', message.data);
      jsMediaEngineVariables.videoDecInitInstance.setWasmSuccess(false);
    } else if (message.status === jsEvent.Video_Dec_Handle_OK) {
      Zoom_Monitor.add_monitor('VDHS');
      jsMediaEngineVariables.videoDecInitInstance.setHanderSuccess();
    } else if (message.status === jsEvent.Video_Dec_Handle_FAILED) {
      Zoom_Monitor.add_monitor('VDHF');
      jsMediaEngineVariables.videoDecInitInstance.setHanderSuccess(false);
    } else if (message.status === jsEvent.Video_Dec_WebSocket_OK) {
      Zoom_Monitor.add_monitor('VDSS');
    } else if (message.status === jsEvent.Video_Dec_WebSocket_FAILED) {
      Zoom_Monitor.add_monitor('VDSF');

      if (jsMediaEngineVariables.videoDecInitInstance.isSocketInitSuccess()) {
        jsMediaEngineVariables.Notify_APPUI_SAFE(
          jsEvent.VIDEO_WEBSOCKET_BROKEN,
          null
        );
      }

      jsMediaEngineVariables.videoDecInitInstance.setSocketSuccess(false);
    } else if (message.status === jsEvent.CONNECT_WEBTRANSPORT_OK) {
      Zoom_Monitor.add_monitor('VDWPS');
    } else if (message.status === jsEvent.CONNECT_WEBTRANSPORT_CLOSE) {
      Zoom_Monitor.add_monitor('VDWPCLOSE');
      jsMediaEngineVariables.sendMessageToRwg(
        jsEvent.SEND_MESSAGE_TO_RWG,
        {
          evt: jsEvent.ZOOM_CONNECTION_REMOVE_UDP_EVT,
          body: {
            flag: 2,
            type: ZOOM_CONNECTION_TYPE.ZOOM_CONNECTION_VIDEO,
          },
        },
        false
      );
    } else if (message.status === jsEvent.CONNECT_WEBSOCKET_CLOSE) {
      Zoom_Monitor.add_monitor('VDWSCLOSE');
    } else if (message.status === jsEvent.CURRENT_MEDIA_DATA_TRANSPORT_TYPE) {
      Zoom_Monitor.add_monitor('VDTTP:' + message.type);
    } else if (message.status == jsEvent.MONITOR_MESSAGE) {
      jsMediaEngineVariables.monitorDecodeVideo = message;
      sendMonitorLog(message.data);
      flag = false;
    } else if (message.status == jsEvent.APP_TROUBLESHOOTING_INFO) {
      flag = false;
      jsMediaEngineVariables.monitorVideoDecodeAPPInfo = message;
      jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
        evt: 4167,
        seq: 1,
        body: {
          data: jsMediaEngineVariables.monitorVideoDecodeAPPInfo.data,
        },
      });
      jsMediaEngineVariables.monitorVideoDecodeAPPInfo = null;
    } else if (message.status == jsEvent.DOWNLOAD_WASM_FROM_MAIN_THREAD) {
      let handle = jsMediaEngineVariables.localVideoDecMGR.map.get(
        jsMediaEngineVariables.SPECIAL_ID
      );
      downloadWASMAndPostToWorker(
        message.url,
        handle,
        WORKER_TYPE.VIDEO_DECODE
      );
      flag = false;
    } else if (message.status == jsEvent.WCL_TROUBLESHOOTING_INFO) {
      flag = false;
      Zoom_Monitor.add_monitor('VD' + message.data);
    } else if (message.status == consts.GLOBAL_TRACING_LOG) {
      flag = false;
      globalTracingLogger.error(
        `Error from video decode worker: ${message.errorMessage}`,
        message.errorEvent
      );
    } else if (message.status === jsEvent.CURRENT_DECODE_VIDEO_QUALITY) {
      jsMediaEngineVariables.Notify_APPUI_SAFE(
        jsEvent.CURRENT_DECODE_VIDEO_QUALITY,
        message.data
      );
    } else if (message.status === jsEvent.CURRENT_DECODE_VIDEO_FPS) {
      jsMediaEngineVariables.Notify_APPUI_SAFE(
        jsEvent.CURRENT_DECODE_VIDEO_FPS,
        message.data
      );
    } else if (message.status === jsEvent.VIDEO_QOS_DATA) {
      const messageData = {
        encoding: false,
        ...message.data,
      };
      jsMediaEngineVariables.Notify_APPUI_SAFE(
        jsEvent.VIDEO_QOS_DATA,
        messageData
      );
    } else if (message.status === jsEvent.HARDWARE_VIDEO_INFO_LOG) {
      Zoom_Monitor.add_monitor(message.data);
    } else if (message.status === 'VIDEO_DECODE_PTR') {
      PubSub.triggerSync('VIDEO_DECODE_PTR', message);
    } else if (message.status === 'FIRST_VIDEO_FRAME') {
      jsMediaEngineVariables.Notify_APPUI_SAFE(jsEvent.FIRST_VIDEO_FRAME);
      Zoom_Monitor.add_monitor('FVF');
    } else if (message.status === jsEvent.NETWORK_QUALITY_CHANGE) {
      jsMediaEngineVariables.Notify_APPUI_SAFE(jsEvent.NETWORK_QUALITY_CHANGE, {
        isUplink: message.isUplink,
        networkLevel: message.networkLevel,
        bwLevel: message.bwLevel,
      });
    } else if (message.status == 'WFMO') {
      flag = false;
      SendWasmMemory(WORKER_TYPE.VIDEO_DECODE);
    } else {
      allWorkersListener(WORKER_TYPE.VIDEO_DECODE, e);
    }
  } catch (ex) {
    log.error(ex);
  }
  if (flag && jsMediaEngineVariables.mediaSDKHandle?.isSupportVideoShare) {
    SharingDec_Listener(e);
  }
}
function VideoEnc_Listener(e) {
  var message = e.data;
  var flag = true;
  /**
   * for faster transport and less GC, WORKER_MAIN_VIDEO_ENCODE_RINGBUFFER_TICK not using JSON_FORMAT_LIKE message
   * other
   */
  if (message === jsEvent.WORKER_MAIN_VIDEO_ENCODE_RINGBUFFER_TICK) {
    let consumer =
      jsMediaEngineVariables.mediaSDKHandle.videoEncodeRingbufferConsumer;
    if (consumer) {
      consumer.consumeAll();
    }
    return;
  }
  try {
    if (message.status === jsEvent.VIDEO_DATA_DROP_RATIO) {
      //todo Notify_APPUI
    } else if (message.status === 'VBPredictDone') {
      Zoom_Monitor.add_monitor('VBPOK' + message.predictCostTime);
      if (jsMediaEngineVariables.isPreviewMode.videoEncode) {
        preserveMessageController.enqueueMessage({
          name: preserveMessageController.nameMap.INIT_VIDEO_ENCODE_SUCCESS,
          messageParams: [jsEvent.VB_MODEL_PRELOADING_OK, null],
          direction: preserveMessageController.direction.SDK_TO_APP,
        });
      }
      jsMediaEngineVariables.Notify_APPUI(jsEvent.VB_MODEL_PRELOADING_OK, null);
    } else if (message.status === 'VBPredictAbout3s') {
      Zoom_Monitor.add_monitor('VBP3S');
      jsMediaEngineVariables.Notify_APPUI(jsEvent.VB_MODEL_PRELOADING_3S, null);
    } else if (message.status === 'VBPredictAbout10s') {
      Zoom_Monitor.add_monitor('VBP10S');
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.VB_MODEL_PRELOADING_10S,
        null
      );
    } else if (message.status === jsEvent.VIDEO_ENCODED_DATA) {
      let data = message.data;
      // video/audio data to websocket do not use async action. because async action will be very slow when some browsers (like Chrome) run in background mode.
      PubSub.triggerSync(jsEvent.VIDEO_DATA_FROM_WORKER, data);
      flag = false;
    } else if (message.status === 0) {
    } else if (
      message.status === jsEvent.Video_Enc_WASM_OK ||
      message.status === jsEvent.Video_Dec_WASM_OK
    ) {
      Zoom_Monitor.add_monitor('VEWS');
      jsMediaEngineVariables.isVideoEncodeWASMOK = true;
      jsMediaEngineVariables.videoInitInstance.setWasmSuccess();
    } else if (message.status === jsEvent.Video_Enc_WASM_FAILED) {
      Zoom_Monitor.add_monitor('VEWF', message.data);
      jsMediaEngineVariables.videoInitInstance.setWasmSuccess(false);
    } else if (
      message.status === jsEvent.Video_Enc_Handle_OK ||
      jsEvent.Video_Dec_Handle_OK === message.status
    ) {
      Zoom_Monitor.add_monitor('VEHS');
      jsMediaEngineVariables.videoInitInstance.setHanderSuccess();
    } else if (
      message.status === jsEvent.Video_Enc_Handle_FAILED ||
      jsEvent.Video_Dec_Handle_FAILED === message.status
    ) {
      Zoom_Monitor.add_monitor('VEHF');
      jsMediaEngineVariables.videoInitInstance.setHanderSuccess(false);
    } else if (
      message.status === jsEvent.Video_Enc_WebSocket_OK ||
      jsEvent.Video_Dec_WebSocket_OK === message.status
    ) {
      Zoom_Monitor.add_monitor('VESS');
      jsMediaEngineVariables.videoInitInstance.setSocketSuccess();
    } else if (
      message.status === jsEvent.Video_Enc_WebSocket_FAILED ||
      message.status === jsEvent.Video_Dec_WebSocket_FAILED
    ) {
      Zoom_Monitor.add_monitor('VESF');
      if (jsMediaEngineVariables.videoInitInstance.isSocketInitSuccess()) {
        jsMediaEngineVariables.Notify_APPUI_SAFE(
          jsEvent.VIDEO_WEBSOCKET_BROKEN,
          null
        );
      }
      jsMediaEngineVariables.videoInitInstance.setSocketSuccess(false);
    } else if (message.status === jsEvent.CONNECT_WEBTRANSPORT_OK) {
      Zoom_Monitor.add_monitor('VEWPS');
    } else if (message.status === jsEvent.CONNECT_WEBTRANSPORT_CLOSE) {
      Zoom_Monitor.add_monitor('VEWPCLOSE');
    } else if (message.status === jsEvent.CONNECT_WEBSOCKET_CLOSE) {
      Zoom_Monitor.add_monitor('VEWSCLOSE');
    } else if (message.status === jsEvent.CURRENT_MEDIA_DATA_TRANSPORT_TYPE) {
      Zoom_Monitor.add_monitor('VETTP:' + message.type);
    } else if (message.status === jsEvent.CURRENT_ENCODED_TYPE) {
      Zoom_Monitor.add_monitor('VEHORS:' + message.type);
    } else if (message.status === jsEvent.Video_Capture_Tick) {
      jsMediaEngineVariables.mediaSDKHandle.Process_Video();
    } else if (
      message.status === jsEvent.CURRENT_CAPTURE_VIDEO_WIDTH_HEIGHT_FROM_WORKER
    ) {
      jsMediaEngineVariables.Notify_APPUI(
        jsEvent.CURRENT_CAPTURE_VIDEO_WIDTH_HEIGHT,
        {
          width: message.width,
          height: message.height,
        }
      );
    } else if (message.status === jsEvent.VIDEO_CAPTURER_RESOLUTION_CHANGE) {
      Zoom_Monitor.add_monitor('VCRC' + message.width);
      jsMediaEngineVariables.mediaSDKHandle.Change_Video_Capture_Resolution(
        message.width,
        message.height
      );
    } else if (message.status == jsEvent.VIDEO_CAPTURE_FRAME_COUNT_STATISTIC) {
      Zoom_Monitor.add_monitor2('VCFOK');
    } else if (message.status == jsEvent.UNSUPPORTED_VIDEO_FORMAT) {
      Zoom_Monitor.add_monitor('VCFF', `format: ${message.format}`);
    } else if (message.status == jsEvent.MONITOR_MESSAGE) {
      flag = false;
      sendMonitorLog(message.data);
      return;
    } else if (message.status == jsEvent.APP_TROUBLESHOOTING_INFO) {
      flag = false;
      jsMediaEngineVariables.monitorVideoEncodeAPPInfo = message;
      sendMonitorLog(jsMediaEngineVariables.monitorVideoEncodeAPPInfo.data);
      jsMediaEngineVariables.monitorVideoEncodeAPPInfo = null;
    } else if (message.status == jsEvent.SHARING_DECODE_MONITOR_MESSAGE) {
      jsMediaEngineVariables.monitorEncodeSharing = message;
    } else if (message.status === jsEvent.DOWNLOAD_WASM_FROM_MAIN_THREAD) {
      let handle = jsMediaEngineVariables.localVideoEncMGR.map.get(
        jsMediaEngineVariables.SPECIAL_ID
      );
      downloadWASMAndPostToWorker(
        message.url,
        handle,
        WORKER_TYPE.VIDEO_ENCODE
      );
      flag = false;
    } else if (message.status == jsEvent.WCL_TROUBLESHOOTING_INFO) {
      flag = false;
      Zoom_Monitor.add_monitor('VE' + message.data);
    } else if (message.status == consts.GLOBAL_TRACING_LOG) {
      flag = false;
      globalTracingLogger.error(
        `Error from video encode worker: ${message.errorMessage}`,
        message.errorEvent
      );
    } else if (message.status == jsEvent.THREAD_ENCODE_BUFFER) {
      let ssrc = jsMediaEngineVariables.SPECIAL_ID;
      if (jsMediaEngineVariables.localVideoDecMGR) {
        var handle = jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
        if (handle) {
          handle.postMessage({
            command: 'encodedbuffer',
            wasmMemory: message.wasmMemory,
          });
        }
      }
    } else if (message.status == jsEvent.WASMPTR) {
      let ssrc = jsMediaEngineVariables.SPECIAL_ID;
      if (jsMediaEngineVariables.localVideoDecMGR) {
        var handle = jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
        if (handle) {
          handle.postMessage({
            command: 'encodedimagebitmapwasmptr',
            data: message.data,
            wasmMemory: message.wasmMemory,
          });
        }
      } else if (jsMediaEngineVariables.isPreviewMode.videoEncode) {
        preserveMessageController.enqueueMessage({
          name: preserveMessageController.nameMap.INIT_VIDEO_DECODE_SUCCESS,
          messageParams: [
            WORKER_TYPE.VIDEO_DECODE,
            {
              command: 'encodedimagebitmapwasmptr',
              data: message.data,
              wasmMemory: message.wasmMemory,
            },
          ],
          direction: preserveMessageController.direction.SDK_TO_WORKER,
        });
      }
    } else if (message.status === jsEvent.VIDEO_QOS_DATA) {
      const messageData = {
        encoding: true,
        ...message.data,
      };
      jsMediaEngineVariables.Notify_APPUI_SAFE(
        jsEvent.VIDEO_QOS_DATA,
        messageData
      );
    } else if (message.status === jsEvent.VIDEOSHARE_QOS_DATA) {
      const messageData = {
        encoding: true,
        ...message.data,
      };
      jsMediaEngineVariables.Notify_APPUI_SAFE(
        jsEvent.VIDEOSHARE_QOS_DATA,
        messageData
      );
    } else if (message.status == jsEvent.MULTIVIEW_WEBGL_CONTEXT_LOST) {
      jsMediaEngineVariables.Notify_APPUI(jsEvent.WEBGL_LOST_IN_MULTI_VIEW, {
        canvasId: message.canvasId,
        replaceCanvas: !!message.replaceCanvas,
      });
    } else if (message.status === 'VIDEO_ENCODE_PTR') {
      PubSub.triggerSync('VIDEO_ENCODE_PTR', message);
    } else if (message.status === jsEvent.Video_Encode_Preview_OK) {
      jsMediaEngineVariables.mediaSDKHandle.init_Notify_APPUI(
        true,
        WORKER_TYPE.VIDEO_ENCODE
      );
    } else if (
      message.status == jsEvent.VIDEO_SHARING_CAPTURER_RESOLUTION_CHANGE
    ) {
      jsMediaEngineVariables.mediaSDKHandle.Change_Sharing_Capture_Resolution(
        message.mode
      );
    } else if (message.status === jsEvent.NETWORK_QUALITY_CHANGE) {
      jsMediaEngineVariables.Notify_APPUI_SAFE(jsEvent.NETWORK_QUALITY_CHANGE, {
        isUplink: message.isUplink,
        networkLevel: message.networkLevel,
        bwLevel: message.bwLevel,
      });
    } else if (message.status == 'WFMO') {
      flag = false;
      SendWasmMemory(WORKER_TYPE.VIDEO_ENCODE);
    } else {
      allWorkersListener(WORKER_TYPE.VIDEO_ENCODE, e);
    }
  } catch (ex) {
    log.error(ex);
  }
  if (flag && jsMediaEngineVariables.mediaSDKHandle?.isSupportVideoShare) {
    SharingEnc_Listener(e);
  }
}

function AllocateWasmSharedMemory(init, max) {
  let memory;
  try {
    memory = new WebAssembly.Memory({
      initial: init,
      maximum: max,
      shared: true,
    });
  } catch (e) {
    globalTracingLogger.error('Allocate WasmMemory Failed');
  }
  return memory;
}

function SendWasmMemory(workerType) {
  let memory;
  let handle;
  let flag = false;
  let maxpages =
    !util.isIphoneOrIpadBrowser() && !util.isIpadOS() ? 4096 * 2 : 4096;
  let reuseFlag =
    jsMediaEngineVariables.mediaSDKHandle.is32bitbrowser ||
    !util.isChromeVersionHigherThan(90) ||
    util.isChromeOS();

  if (workerType == WORKER_TYPE.VIDEO_ENCODE) {
    handle = jsMediaEngineVariables.localVideoEncMGR.map.get(0);
    if (!window['VE']) {
      if (jsMediaEngineVariables.mediaSDKHandle?.isSupportVideoShare) {
        maxpages = 32000;
      }
      memory = AllocateWasmSharedMemory(1024, maxpages);
      if (reuseFlag) {
        window['VE'] = memory;
      }
    } else {
      flag = true;
      memory = window['VE'];
    }
  } else if (workerType == WORKER_TYPE.VIDEO_DECODE) {
    handle = jsMediaEngineVariables.localVideoDecMGR.map.get(0);
    if (!window['VD']) {
      if (jsMediaEngineVariables.mediaSDKHandle?.isSupportVideoShare) {
        maxpages = 16384;
      }
      memory = AllocateWasmSharedMemory(1024, maxpages);
      if (reuseFlag) {
        window['VD'] = memory;
      }
    } else {
      flag = true;
      memory = window['VD'];
    }
  } else if (workerType == WORKER_TYPE.SHARING_ENCODE) {
    handle = jsMediaEngineVariables.localSharingEncMGR.map.get(0);
    if (!window['SE']) {
      memory = AllocateWasmSharedMemory(1024, maxpages);
      if (reuseFlag) {
        window['SE'] = memory;
      }
    } else {
      flag = true;
      memory = window['SE'];
    }
  } else if (workerType == WORKER_TYPE.SHARING_DECODE) {
    handle = jsMediaEngineVariables.localSharingDecMGR.map.get(0);
    if (!window['SD']) {
      memory = AllocateWasmSharedMemory(1024, maxpages);
      if (reuseFlag) {
        window['SD'] = memory;
      }
    } else {
      flag = true;
      memory = window['SD'];
    }
  }

  if (flag) {
    let setmemory0 = new Uint8Array(memory.buffer);
    setmemory0.fill(0);
  }
  handle.postMessage({ command: 'wasmMemory', data: memory });
}

function downloadWASMAndPostToWorker(url, handle, workerType) {
  util
    .downloadAndCompileWebAssembly(
      url,
      workerType,
      Zoom_Monitor,
      !util.browser.isSafari &&
        jsMediaEngineVariables.enableStreamingInstantiate
    )
    .then((wasmModule) => {
      handle.postMessage({
        command: 'DOWNLOAD_WASM_FROM_MAIN_THREAD_OK',
        data: wasmModule,
      });
    })
    .catch((ex) => {
      handle.postMessage({
        command: 'DOWNLOAD_WASM_FROM_MAIN_THREAD_FAILED',
        data: ex,
      });
    });
}

/**
 * @description recv new audio monitor log
 */
export const addAudioMonitorLog = (function () {
  let localLog = {
    send: null,
    recv: null,
  };
  let timer = null;
  /**
   * @description log from worker
   * @example
   * 'WCL_MCM_AUDIO,68136961,9,,{[SPEECHINPUT_R16]},12211211122,00000000000,12211211122,00000000000,{[SEND]},10,34,{[DENOISE]},1,0,0,{[CAPTURE]},1410,{[END]}'
   * 'WCL_MCM_AUDIO,68136960,,{[SPEECHOUTPUT_R16]},4556555544,{[NETWORK]},66,75,81,{[RENDER]},1333,{[AUDIOSCORE]},68133889,932,655,0,0,0,0,0,20,60,2,30,932,{[QUALITY]},4.40929,4.40929,{[END]}'
   *  https://zoomvideo.atlassian.net/wiki/spaces/BI/pages/1841598119/Conf+Data+Log+-+In-Meeting+Data+Log+Definition#DataType%3A-WCL_MCM_AUDIO
   */
  let SPEECH_INPUT_REG =
    /(WCL_MCM_AUDIO.*?)(\{\[SPEECHINPUT_R16\]\}),(.*?),(.*?),(.*?),(.*?),(\{\[SEND\]\}.*?)(\{\[DENOISE\]\}.*?)\{\[CAPTURE\]\},(.*?),(\{\[END\]\})/;
  let SPEECH_OUTPUT_REG =
    /(WCL_MCM_AUDIO.*?)(\{\[SPEECHOUTPUT_R16\]\},)(.*?),(\{\[NETWORK\]\}.*?)\{\[RENDER\]\},(.*?),(.*?)(\{\[END\]\})/;
  return {
    push({ log, isSend }) {
      if (!log) return;
      if (!this._isLogValid(log)) return;
      if (this._checkNeedReportImmediate(log)) return this._report(log); // for wcl_jitter

      if (isSend) {
        localLog.send = log;
      } else {
        localLog.recv = log;
      }
      this._clearTImer();
      timer = setTimeout(this._checkNeedReport.bind(this), 2 * 1000);
    },
    _isLogValid(log) {
      return log.match('WCL_MCM_AUDIO') ||
        log.match('WCL_JITTER') ||
        log.match('WCL_DEVICE') ||
        log.match('ORIGINAL_SOUND')
        ? true
        : false;
    },

    _checkNeedReportImmediate(log) {
      if (log.match('SPEECHOUTPUT_R16') || log.match('SPEECHINPUT_R16'))
        return false;
      return true;
    },

    _checkNeedReport() {
      const log = this._generateLog();
      if (!log) return;
      this._report(log);
    },

    _generateLog() {
      if (!localLog.send && !localLog.recv) return null;

      const {
        inputputPrefix,
        speechInStr,
        speechInOutStr,
        denoiseInStr,
        denoiseOutStr,
        send,
        denoise,
        captureCounter,
      } = this._parseSend(localLog.send);
      const { outputPrefix, speechOutStr, network, renderCounter, others } =
        this._parseRecv(localLog.recv);
      const prefix = inputputPrefix || outputPrefix;
      const content = `{[SPEECH_R16]},${speechInStr},${speechInOutStr},${speechOutStr},${denoiseInStr},${denoiseOutStr},{[PROCESS]},${captureCounter},${renderCounter},${network}${others}${send}${denoise}{[END]}`;
      return `${prefix}${content}`;
    },

    // /(WCL_MCM_AUDIO.*?)(\{\[SPEECHINPUT_R16\]\}),(.*?),(.*?),(.*?),(.*?),(\{\[SEND\]\}.*?)(\{\[DENOISE\]\}.*?)(\{\[CAPTURE\]\}.*?)(\{\[END\]\})/
    _parseSend(log) {
      const matched = SPEECH_INPUT_REG.exec(log || '') || [];
      return {
        inputputPrefix: matched[1] || '',
        speechInStr: matched[3] || '',
        speechInOutStr: matched[4] || '',
        denoiseInStr: matched[5] || '',
        denoiseOutStr: matched[6] || '',
        send: matched[7] || '',
        denoise: matched[8] || '',
        captureCounter: matched[9] || '',
      };
    },

    // /(WCL_MCM_AUDIO.*?)(\{\[SPEECHOUTPUT_R16\]\},)(.*?),(\{\[NETWORK\]\}.*?)\{\[RENDER\]\},(.*),(.*?)(\{\[END\]\})/
    _parseRecv(log) {
      const matched = SPEECH_OUTPUT_REG.exec(log || '') || [];
      return {
        outputPrefix: matched[1] || '',
        speechOutStr: matched[3] || '',
        network: matched[4] || '',
        renderCounter: matched[5] || '',
        others: matched[6] || '',
      };
    },
    _report(log) {
      if (log)
        jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
          evt: 4167,
          seq: 1,
          body: {
            data: log,
          },
        });
    },
    _clearTImer() {
      clearTimeout(timer);
      timer = null;
    },
    clear() {
      localLog = {
        send: null,
        recv: null,
      };
      this._clearTImer();
    },
  };
})();

function AudioDec_Listener(e) {
  var message = e.data;
  try {
    if (message.status === 0) {
      if (message.time) {
        if (jsMediaEngineVariables.AudioNode) {
          jsMediaEngineVariables.AudioNode.postData('data', {
            ssrc: jsMediaEngineVariables.CurrentSSRC,
            data: message.data,
            time: message.time,
          });
        }
      } else {
        if (jsMediaEngineVariables.AudioNode) {
          jsMediaEngineVariables.AudioNode.postData('data', {
            ssrc: jsMediaEngineVariables.CurrentSSRC,
            data: message.data,
            time: null,
          });
        }
      }
    } else if (
      message.status === jsEvent.Audio_Enc_WASM_OK ||
      message.status === jsEvent.Audio_Dec_WASM_OK
    ) {
      Zoom_Monitor.add_monitor('ADWS');
      jsMediaEngineVariables.isAudioDecodeWASMOK = true;
      jsMediaEngineVariables.audioDecInitInstance.setWasmSuccess();
      if (jsMediaEngineVariables.isPreviewMode.audioDecode) {
        jsMediaEngineVariables.Notify_APPUI_SAFE(
          jsEvent.PREVIEW_INIT_AUDIO_DECODE_SUCCESS
        );
      }
    } else if (message.status === jsEvent.Audio_Dec_WASM_FAILED) {
      //jsMediaEngineVariables.Notify_APPUI
      Zoom_Monitor.add_monitor('ADWF', message.data);
      jsMediaEngineVariables.audioDecInitInstance.setWasmSuccess(false);
    } else if (message.status === jsEvent.Audio_Dec_Handle_OK) {
      Zoom_Monitor.add_monitor('ADHS');
      jsMediaEngineVariables.audioDecInitInstance.setHanderSuccess();
    } else if (message.status === jsEvent.Audio_Dec_Handle_FAILED) {
      Zoom_Monitor.add_monitor('ADHF');
      jsMediaEngineVariables.audioDecInitInstance.setHanderSuccess(false);
    } else if (message.status === jsEvent.Audio_Dec_WebSocket_OK) {
      Zoom_Monitor.add_monitor('ADSS');
      jsMediaEngineVariables.audioDecInitInstance.setSocketSuccess();
    } else if (message.status === jsEvent.Audio_Dec_WebSocket_FAILED) {
      Zoom_Monitor.add_monitor('ADSF');
      if (jsMediaEngineVariables.audioDecInitInstance.isSocketInitSuccess()) {
        jsMediaEngineVariables.Notify_APPUI_SAFE(
          jsEvent.AUDIO_WEBSOCKET_BROKEN,
          null
        );
      }
      jsMediaEngineVariables.audioDecInitInstance.setSocketSuccess(false);
    } else if (message.status === jsEvent.CONNECT_WEBTRANSPORT_OK) {
      Zoom_Monitor.add_monitor('ADWPS');
    } else if (message.status === jsEvent.CONNECT_WEBTRANSPORT_CLOSE) {
      Zoom_Monitor.add_monitor('ADWPCLOSE');
      jsMediaEngineVariables.sendMessageToRwg(
        jsEvent.SEND_MESSAGE_TO_RWG,
        {
          evt: jsEvent.ZOOM_CONNECTION_REMOVE_UDP_EVT,
          body: {
            flag: 2,
            type: ZOOM_CONNECTION_TYPE.ZOOM_CONNECTION_AUDIO,
          },
        },
        false
      );
    } else if (message.status === jsEvent.CONNECT_WEBSOCKET_CLOSE) {
      Zoom_Monitor.add_monitor('ADWSCLOSE');
    } else if (message.status === jsEvent.CURRENT_MEDIA_DATA_TRANSPORT_TYPE) {
      Zoom_Monitor.add_monitor('ADTTP:' + message.type);
    } else if (message.status == jsEvent.MONITOR_MESSAGE) {
      jsMediaEngineVariables.monitorDecodeAudio = message;
    } else if (message.status === jsEvent.DOWNLOAD_WASM_FROM_MAIN_THREAD) {
      let handle = jsMediaEngineVariables.localAudioDecMGR.map.get(
        jsMediaEngineVariables.SPECIAL_ID
      );
      downloadWASMAndPostToWorker(
        message.url,
        handle,
        WORKER_TYPE.AUDIO_DECODE
      );
    } else if (message.status == jsEvent.WCL_TROUBLESHOOTING_INFO) {
      Zoom_Monitor.add_monitor('AD' + message.data);
    } else if (message.status == consts.GLOBAL_TRACING_LOG) {
      globalTracingLogger.error(
        `Error from audio decode worker: ${message.errorMessage}`,
        message.errorEvent
      );
    } else if (message.status == jsEvent.CURRENT_SSRC_TIME) {
      if (message.at) {
        if (jsMediaEngineVariables.CurrentSSRCTime !== message.at) {
          jsMediaEngineVariables.CurrentSSRCTime = message.at;
          jsMediaEngineVariables.audioPlayTime = Date.now();
        }
      }
      if (
        message.st &&
        jsMediaEngineVariables.mediaSDKHandle.SharingRenderObj
      ) {
        jsMediaEngineVariables.mediaSDKHandle.SharingRenderObj.SetcATimeStamp(
          message.st
        );
      }
    } else if (message.status == 'multiCurrentTime') {
      if (jsMediaEngineVariables.localVideoDecMGR) {
        var handle = jsMediaEngineVariables.localVideoDecMGR.map.get(
          jsMediaEngineVariables.SPECIAL_ID
        );
        if (handle) {
          handle.postMessage({
            command: 'audioDecodeTime',
            data: message.data,
            status: 1,
          });
        }
      }
    } else if (message.status == jsEvent.AUDIO_ENCODED_DATA) {
      // video/audio data to websocket do not use async action. because async action will be very slow when some browsers (like Chrome) run in background mode.
      PubSub.triggerSync(jsEvent.AUDIO_DATA_FROM_WORKER, message.data);
    } else if (message.status === jsEvent.AUDIO_MONITOR_LOG) {
      addAudioMonitorLog.push({
        log: message.data,
        isSend: false,
      });
    } else if (message.status === jsEvent.AUDIO_QOS_DATA) {
      const messageData = {
        encoding: false,
        ...message.data,
      };
      jsMediaEngineVariables.Notify_APPUI_SAFE(
        jsEvent.AUDIO_QOS_DATA,
        messageData
      );
    } else {
      allWorkersListener(WORKER_TYPE.AUDIO_DECODE, e);
    }
  } catch (ex) {
    log.error(ex);
  }
}

function AudioEnc_Listener(e) {
  if (
    !jsMediaEngineVariables.localAudioDecMGR &&
    !jsMediaEngineVariables.isPreviewMode.audioEncode
  ) {
    return;
  }

  try {
    let message = e.data;
    if (message.status === jsEvent.WORKER_MAIN_AUDIO_ENCODE_RINGBUFFER_TICK) {
      let consumer =
        jsMediaEngineVariables.mediaSDKHandle.audioEncodeRingBufferConsumer;
      if (consumer) {
        consumer.consumeAll(true);
      }
    } else if (message.status == 0) {
      var ssrc = jsMediaEngineVariables.SPECIAL_ID;
      var audio_decwebwork =
        jsMediaEngineVariables.localAudioDecMGR.map.get(ssrc);
      if (audio_decwebwork) {
        audio_decwebwork.postMessage(
          {
            command: 'EncodedAudioFrame',
            data: message.data,
          },
          [message.data.buffer]
        );
      }
    } else if (message.status == jsEvent.AUDIO_ENCODED_DATA) {
      // video/audio data to websocket do not use async action. because async action will be very slow when some browsers (like Chrome) run in background mode.
      PubSub.triggerSync(jsEvent.AUDIO_DATA_FROM_WORKER, message.data);
    } else if (
      message.status === jsEvent.Audio_Enc_WASM_OK ||
      message.status === jsEvent.Audio_Dec_WASM_OK
    ) {
      Zoom_Monitor.add_monitor('AEWS');
      jsMediaEngineVariables.isAudioEncodeWASMOK = true;
      jsMediaEngineVariables.audioEncodeInitInstance.setWasmSuccess();
    } else if (
      message.status === jsEvent.Audio_Enc_WASM_FAILED ||
      message.status === jsEvent.Audio_Dec_WASM_FAILED
    ) {
      Zoom_Monitor.add_monitor('AEWF', message.data);
      jsMediaEngineVariables.audioEncodeInitInstance.setWasmSuccess(false);
    } else if (message.status === jsEvent.Audio_Dec_Handle_OK) {
      Zoom_Monitor.add_monitor('AEHS');
      jsMediaEngineVariables.audioEncodeInitInstance.setHanderSuccess();
    } else if (message.status === jsEvent.Audio_Dec_WebSocket_OK) {
      Zoom_Monitor.add_monitor('AESS');
      jsMediaEngineVariables.audioEncodeInitInstance.setSocketSuccess();
    } else if (message.status === jsEvent.Audio_Dec_WebSocket_FAILED) {
      Zoom_Monitor.add_monitor('AESF');
      if (
        jsMediaEngineVariables.audioEncodeInitInstance.isSocketInitSuccess()
      ) {
        jsMediaEngineVariables.Notify_APPUI_SAFE(
          jsEvent.AUDIO_WEBSOCKET_BROKEN,
          null
        );
      }
      jsMediaEngineVariables.audioEncodeInitInstance.setSocketSuccess(false);
    } else if (message.status === jsEvent.Audio_Dec_Handle_FAILED) {
      Zoom_Monitor.add_monitor('AEHF');
      jsMediaEngineVariables.audioEncodeInitInstance.setHanderSuccess(false);
    } else if (message.status === jsEvent.Audio_Enc_Handle_FAILED) {
      jsMediaEngineVariables.Notify_APPUI_SAFE(
        jsEvent.Audio_Enc_Handle_FAILED,
        null
      );
    } else if (message.status === jsEvent.CONNECT_WEBTRANSPORT_OK) {
      Zoom_Monitor.add_monitor('AEWPS');
    } else if (message.status === jsEvent.CONNECT_WEBTRANSPORT_CLOSE) {
      Zoom_Monitor.add_monitor('AEWPCLOSE');
    } else if (message.status === jsEvent.CONNECT_WEBSOCKET_CLOSE) {
      Zoom_Monitor.add_monitor('AEWSCLOSE');
    } else if (message.status === jsEvent.CURRENT_MEDIA_DATA_TRANSPORT_TYPE) {
      Zoom_Monitor.add_monitor('AETTP:' + message.type);
    } else if (message.status === jsEvent.Audio_Mute) {
      if (jsMediaEngineVariables.Notify_APPUI) {
        jsMediaEngineVariables.Notify_APPUI(jsEvent.AUDIO_ZERO_DATA, null);
      }
    } else if (message.status === jsEvent.AUDIO_DELAY) {
      jsMediaEngineVariables.indexDbObject.put(message.delay, 'delay');
      if (jsMediaEngineVariables.openIndexFlag) {
        jsMediaEngineVariables.indexDbObject.select('delay');
      }
    } else if (message.status === jsEvent.DOWNLOAD_WASM_FROM_MAIN_THREAD) {
      let handle = jsMediaEngineVariables.localAudioEncMGR.map.get(
        jsMediaEngineVariables.SPECIAL_ID
      );
      downloadWASMAndPostToWorker(
        message.url,
        handle,
        WORKER_TYPE.AUDIO_ENCODE
      );
    } else if (message.status == jsEvent.WCL_TROUBLESHOOTING_INFO) {
      Zoom_Monitor.add_monitor('AE' + message.data);
    } else if (message.status == consts.GLOBAL_TRACING_LOG) {
      globalTracingLogger.error(
        `Error from audio encode worker: ${message.errorMessage}`,
        message.errorEvent
      );
    } else if (message.status == jsEvent.AUDIO_CLIPPING) {
      //todo
      //just for test
      // console.error("AUDIO_CLIPPING")
      // jsMediaEngineVariables.Notify_APPUI(jsEvent.AUDIO_CLIPPING, null)
    } else if (message.status === jsEvent.AUDIO_MONITOR_LOG) {
      addAudioMonitorLog.push({
        log: message.data,
        isSend: true,
      });
    } else if (message.status === jsEvent.AUDIO_QOS_DATA) {
      const messageData = {
        encoding: true,
        ...message.data,
      };
      jsMediaEngineVariables.Notify_APPUI_SAFE(
        jsEvent.AUDIO_QOS_DATA,
        messageData
      );
    } else if (message.status === jsEvent.Audio_Encode_Preview_OK) {
      jsMediaEngineVariables.mediaSDKHandle.init_Notify_APPUI(
        true,
        WORKER_TYPE.AUDIO_ENCODE
      );
    } else if (message.status === jsEvent.SPEAKING_WHEN_MUTE) {
      jsMediaEngineVariables.Notify_APPUI_SAFE(jsEvent.SPEAKING_WHEN_MUTE);
    } else {
      allWorkersListener(WORKER_TYPE.AUDIO_ENCODE, e);
    }
  } catch (ex) {
    log.error(ex);
  }
}

async function Add_Sharing_Encode_Thread(sdkInstance, workerParameters) {
  if (sdkInstance && sdkInstance.isDestroy) {
    return log(
      `WorkerType:sharingEnc;The relative SDK instance is destroy, don't start relative worker, avoid multiple same workers. `
    );
  }

  if (!jsMediaEngineVariables.isSharingEncodeThreadStart) {
    jsMediaEngineVariables.isSharingEncodeThreadStart = true;
    await WorkerStart(
      workerParameters,
      jsMediaEngineVariables.localSharingEncMGR,
      SharingEnc_Listener,
      WORKER_TYPE.SHARING_ENCODE,
      sdkInstance
    );
  }
}

async function Add_Sharing_Decode_Thread(sdkInstance, workerParameters) {
  if (!jsMediaEngineVariables.isSharingDecodeThreadStart) {
    jsMediaEngineVariables.isSharingDecodeThreadStart = true;
    await WorkerStart(
      workerParameters,
      jsMediaEngineVariables.localSharingDecMGR,
      SharingDec_Listener,
      WORKER_TYPE.SHARING_DECODE,
      sdkInstance
    );
  }
}

async function initAudioSharedBuffer() {
  // try {
  //     //we allow the largest number of sample is 10
  //     //we struct like this:
  //     //|                  LOCK             |         cout    |      Audio HZ   |        len      |              data                                   |
  //     //|********|********|********|********|********|********|********|********|********|********|********|********|********|********|********|********|
  //     jsMediaEngineVariables.sharedBuffer = new SharedArrayBuffer(jsMediaEngineVariables.shareBufferSampleNumb * 480 * Int32Array.BYTES_PER_ELEMENT + jsMediaEngineVariables.shareBufferSampleNumb * (2 * 2 + 2 + 2 * 2 + 2));
  //     var sharedArray = new Int32Array(jsMediaEngineVariables.sharedBuffer);
  //     Lock.initialize(sharedArray, 0);
  // } catch (e) {
  //     jsMediaEngineVariables.sharedBuffer = null;
  // }

  //// just use for encode
  //      |1 Byte for read ready flag|4 Byte for read index|1 Byte for write flag|4 Byte for write index|640 * 2 byte to store data|
  /** safari 15+ audio worklet has bug with sab */
  const isSafariVersionLowerThan16 =
    util.browser.isSafari && util.getMacSafariMajorVersion() < 16;
  if (
    !jsMediaEngineVariables.sharedBuffer &&
    util.isSupportSharedArrayBuffer() &&
    !isSafariVersionLowerThan16
  ) {
    log('LP2PSP');
    let multiple = 1;
    if (
      util.browser.isFirefox ||
      util.getAudioContextConfigure().sampleRate === 48000
    ) {
      multiple = 48; // for firefox, 48k should use larger buffer.
    }
    let audioEncodeChannelsNum = util.isSupportMicSendStereo() ? 2 : 1;
    let sharingEncodeChannelsNum = util.isSupportSharingStereo() ? 2 : 1;
    jsMediaEngineVariables.sharedBuffer = {
      /// |4 Byte for read ready flag|4 Byte for read index|4 Byte for write flag|4 Byte for write index|4 Byte for buffer can use data|
      inputState: new SharedArrayBuffer(6 * 4),
      inputBuffer: new SharedArrayBuffer(
        multiple * 640 * 4 * 4 * audioEncodeChannelsNum
      ),

      sharingInputState: new SharedArrayBuffer(6 * 4),
      sharingInputBuffer: new SharedArrayBuffer(
        multiple * 640 * 4 * 4 * sharingEncodeChannelsNum
      ),

      /// |4 Byte for read ready flag|4 Byte for read index|4 Byte for write flag|4 Byte for write index|4 Byte for buffer can use data|4 Byte for data sync|
      outputState: new SharedArrayBuffer(6 * 4),
      outputBuffer: new SharedArrayBuffer(multiple * 640 * 4 * 4 * 2),

      // 4 bytes for read ptr, 4 bytes for write ptr, treat max UDP packet size as 1200, 100 packets buffer size
      rtpBuffer: new SharedArrayBuffer(8 + 1200 * 100),
    };
  }
  Zoom_Monitor.add_monitor('INITSAB' + !!jsMediaEngineVariables.sharedBuffer);
  jsMediaEngineVariables.decoderinworklet =
    jsMediaEngineVariables.decoderinworkletOP &&
    !!jsMediaEngineVariables.sharedBuffer;
}

export function setSharingEngineInitProperties(websocketUrl, param) {
  if (!jsMediaEngineVariables.localSharingPara) {
    jsMediaEngineVariables.localSharingPara = param;
  } else {
    Object.assign(jsMediaEngineVariables.localSharingPara, param);
  }

  Set_Sharing_WebSocket_Ip_Address(websocketUrl);
}

export function setAudioEngineInitProperties(websocketUrl, param) {
  if (!jsMediaEngineVariables.localAudioPara) {
    jsMediaEngineVariables.localAudioPara = param;
  } else {
    Object.assign(jsMediaEngineVariables.localAudioPara, param);
  }

  Set_Audio_WebSocket_Ip_Address(websocketUrl);
}

/**
 *
 * @param websocketUrl
 * @param param {Object}  key-value-pair
 */
export function setVideoEngineInitProperties(websocketUrl, param) {
  if (!jsMediaEngineVariables.localVideoPara) {
    jsMediaEngineVariables.localVideoPara = param;
  } else {
    Object.assign(jsMediaEngineVariables.localVideoPara, param);
  }

  Set_Video_WebSocket_Ip_Address(websocketUrl);
}

export function Set_Audio_WebSocket_Ip_Address(websocket_ip_address) {
  jsMediaEngineVariables.Audio_WebSocket_Ip_Address = websocket_ip_address;
}

export function Set_Audio_Web_Transport_Ip_Address(web_transport_ip_address) {
  jsMediaEngineVariables.Audio_Web_Transport_Ip_Address =
    web_transport_ip_address;
}

function Set_Sharing_WebSocket_Ip_Address(websocket_ip_address) {
  jsMediaEngineVariables.Sharing_WebSocket_Ip_Address = websocket_ip_address;
}

export function Set_Video_WebSocket_Ip_Address(websocket_ip_address) {
  jsMediaEngineVariables.Video_WebSocket_Ip_Address = websocket_ip_address;
}

export function Set_Video_Web_Transport_Ip_Address(web_transport_ip_address) {
  jsMediaEngineVariables.Video_Web_Transport_Ip_Address =
    web_transport_ip_address;
}

export function JsAudioEngine_UnInit() {
  Delete_Audio_Encode_Thread(jsMediaEngineVariables.SPECIAL_ID);
  Delete_Audio_Decode_Thread(jsMediaEngineVariables.SPECIAL_ID);

  //todo
  //Dose this leak memory?
  jsMediaEngineVariables.localAudioDecMGR = null;
  jsMediaEngineVariables.localAudioEncMGR = null;
}

export function JsVideoEngine_UnInit() {
  // Delete_Video_Encode_Thread(jsMediaEngineVariables.SPECIAL_ID);
  Delete_Video_Decode_Thread(jsMediaEngineVariables.SPECIAL_ID);

  //todo
  //Dose this leak memory?
  jsMediaEngineVariables.localVideoDecMGR = null;
  jsMediaEngineVariables.localVideoEncMGR = null;
}

export function JsSharingEngine_UnInit() {
  Delete_Sharing_Decode_Thread();
}

function Delete_Sharing_Decode_Thread() {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localSharingDecMGR) {
    var handle = jsMediaEngineVariables.localSharingDecMGR.Get(ssrc);
    if (handle) {
      handle.postMessage({
        command: 'closeMedia', //websocket keepalive, keep handle,
      });
      jsMediaEngineVariables.localSharingDecMGR = null;
    }
    jsMediaEngineVariables.isSharingDecodeThreadStart = false;
  }
}

function Float32ArrayToInt16Array(float32) {
  var local;
  var i = 0;
  for (i = 0; i < float32.length; i++) {
    if (jsMediaEngineVariables.int16Array === null) {
      jsMediaEngineVariables.int16Array = new Int16Array(float32.length);
    }
    local = float32[i] * 32768;
    if (float32[i] > 32767) {
      local = 32767;
    } else if (float32[i] < -32768) {
      local = -32768;
    }
    jsMediaEngineVariables.int16Array[i] = local;
  }
  return jsMediaEngineVariables.int16Array;
}

export function Video_Encode_Frame(ssrc, video_data, width, x, y) {
  if (jsMediaEngineVariables.localVideoEncMGR) {
    var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
    if (handle) {
      var data = {
        command: 'encodeVideoFrame',
        data: video_data,
        stride: width,
        x: x,
        y: y,
      };
      handle.postMessage(data, [data.data.buffer]);
    }
  }
}

export function Sharing_Encode_Frame(
  ssrc,
  sharing_data,
  length,
  time_stamp,
  width,
  height
) {
  if (true) {
    var handle;
    if (jsMediaEngineVariables.mediaSDKHandle?.isSupportVideoShare) {
      handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
    } else {
      handle = jsMediaEngineVariables.localSharingEncMGR.map.get(ssrc);
    }
    if (handle) {
      let data;
      if (sharing_data) {
        data = {
          command: 'encodeSharingFrame',
          data: sharing_data,
          width: width,
          height: height,
        };
        handle.postMessage(data, [data.data.buffer]);
      } else {
        data = { command: 'encodeSharingFrame' };
        handle.postMessage(data);
      }
    }
  }
}

function Put_Sharing_Data_Buffer(message) {
  if (jsMediaEngineVariables.localSharingDecMGR) {
    if (message.type == 'sharingPara') {
      Zoom_Monitor.add_monitor('SHPA');
      PubSub.publish(jsEvent.SHARING_PARAM_INFO_FROM_SOCKET, message.data);
      jsMediaEngineVariables.Notify_APPUI(jsEvent.SHARING_PARA, message.data);
    }

    if (jsMediaEngineVariables.isSharingPlayWork) {
      jsMediaEngineVariables.localSharingDecMGR.PutData(message);
    }
  }
}

export function Put_Video_Frame_Buffer(
  ssrc,
  buffer,
  time_stamp,
  video_width,
  video_heigth,
  shift_x,
  shift_y,
  shift_w,
  shift_h,
  rotation,
  yuv_limited
) {
  if (!jsMediaEngineVariables.isVideoPlayWork) {
    return;
  }
  var message = {
    yuvdata: buffer,
    ntptime: time_stamp,
    ssrc: ssrc,
    width: video_width,
    height: video_heigth,
    r_x: shift_x,
    r_y: shift_y,
    r_w: shift_w,
    r_h: shift_h,
    rotation: rotation,
    yuv_limited: yuv_limited,
  };
  message.ssrc = (message.ssrc >> 10) << 10;
  jsMediaEngineVariables.mediaSDKHandle.VideoRenderObj.Put_Video_Data_Queue(
    message,
    15
  );
}

function Put_Sharing_Frame_Buffer(
  ssrc,
  buffer,
  time_stamp,
  sharing_width,
  sharing_heigth,
  shift_x,
  shift_y,
  shift_w,
  shift_h,
  logic_w,
  logic_h,
  yuv_limited,
  isFromMainSession
) {
  if (!jsMediaEngineVariables.isSharingPlayWork) {
    return;
  }
  var message = {
    yuvdata: buffer,
    ntptime: time_stamp,
    ssrc: ssrc,
    width: sharing_width,
    height: sharing_heigth,
    r_x: shift_x,
    r_y: shift_y,
    r_w: shift_w,
    r_h: shift_h,
    logic_w: logic_w,
    logic_h: logic_h,
    yuv_limited: yuv_limited,
    isFromMainSession: isFromMainSession,
  };
  jsMediaEngineVariables.mediaSDKHandle.SharingRenderObj.Put_Sharing_Data_From_Queue(
    message
  );
}

function Put_Mouse_Data_Buffer(
  ssrc,
  buffer,
  time_stamp,
  mouse_width,
  mouse_heigth,
  shift_x,
  shift_y,
  mLogic_w,
  mLogic_h,
  sync_id
) {
  if (!jsMediaEngineVariables.isSharingPlayWork) {
    return;
  }
  var message = {
    buffer: buffer,
    ntptime: time_stamp,
    ssrc: ssrc,
    width: mouse_width,
    height: mouse_heigth,
    r_x: shift_x,
    r_y: shift_y,
    mLogic_w: mLogic_w,
    mLogic_h: mLogic_h,
    sync_id: sync_id,
  };
  jsMediaEngineVariables.mediaSDKHandle.SharingRenderObj.Put_Mouse_Data_Into_Queue(
    message
  );
}

export function Clear_Decoded_Sharing_Frame() {
  if (jsMediaEngineVariables.localSharingDecMGR) {
    return jsMediaEngineVariables.localSharingDecMGR.SharingQueueMGR.ClearQueue();
  }
  if (jsMediaEngineVariables.localMouseDecMGR) {
    return jsMediaEngineVariables.localMouseDecMGR.SharingQueueMGR.ClearQueue();
  }
  return 0;
}

async function Video_Decode_Post_message(sdkInstance) {
  if (jsMediaEngineVariables.isPreviewMode.videoDecode) return;
  if (!jsMediaEngineVariables.localVideoDecMGR) {
    return;
  }
  await jsMediaEngineVariables.videoDecInitInstance.wasmSuccessPromise;
  if (!AssertMediaSdkNotDestory(sdkInstance)) {
    return false;
  }
  var handle = jsMediaEngineVariables.localVideoDecMGR.map.get(
    jsMediaEngineVariables.SPECIAL_ID
  );
  if (handle && !jsMediaEngineVariables.isVideoDecodePostStart) {
    jsMediaEngineVariables.isVideoDecodePostStart = true;

    let message = {
      command: 'startMedia',
      _id: sdkInstance._id,
      websocket_ip_address:
        jsMediaEngineVariables.Video_WebSocket_Ip_Address + '&mode=5',
      confId: jsMediaEngineVariables.localVideoPara.confId,
      confKey: '',
      logon: jsMediaEngineVariables.localVideoPara.logon,
      mtu_size: 0,
      meetingid: jsMediaEngineVariables.localVideoPara.meetingid,
      meetingnumb: jsMediaEngineVariables.localVideoPara.meetingnumb,
      videoencodethreadnumb: 1,
      videodecodethreadnumb:
        jsMediaEngineVariables.localVideoPara.videodecodethreadnumb,
      isFirefox: jsMediaEngineVariables.localVideoPara.isFirefox,
      isSupportMultiThread:
        jsMediaEngineVariables.localVideoPara.isSupportMultiThread,
      isSupportVideoTrackReader:
        jsMediaEngineVariables.localVideoPara.isSupportVideoTrackReader,
      isSupportOffscreenCanvas:
        jsMediaEngineVariables.localVideoPara.isSupportOffscreenCanvas,
      isenablehw: jsMediaEngineVariables.localVideoPara.isenablehw,
      isEnableVideoDecodeHardWareThread:
        jsMediaEngineVariables.localVideoPara.isEnableVideoDecodeHardWareThread,
      qoson: jsMediaEngineVariables.localVideoPara.qoson,
      isEnableHardWareThread:
        jsMediaEngineVariables.localVideoPara.isEnableHardWareThread,
      isTeslaMode: jsMediaEngineVariables.localVideoPara.isTeslaMode,
      enableMultiDecodeVideoWithoutSAB:
        !!jsMediaEngineVariables.enableMultiDecodeVideoWithoutSAB,
      enableReplaceCanvasWhenContextLost:
        jsMediaEngineVariables.enableReplaceCanvasWhenContextLost,
      ...(jsMediaEngineVariables.Video_Web_Transport_Ip_Address
        ? {
            webtransportURL:
              jsMediaEngineVariables.Video_Web_Transport_Ip_Address + '&mode=1',
          }
        : {}),
      enableAudioBridge: jsMediaEngineVariables.enableAudioBridge,
      graphicalname: util.graphicName,
      vendorname: util.graphicvendorname,
      platformType: jsMediaEngineVariables.localVideoPara.platformType,
    };

    handle.postMessage(message);
  }
}

export async function Add_Video_Decode_Thread(
  ssrc,
  ip_address,
  sdkInstance,
  workerParameters
) {
  if (!jsMediaEngineVariables.isVideoDecodeThreadStart) {
    jsMediaEngineVariables.isVideoDecodeThreadStart = true;
    await WorkerStart(
      workerParameters,
      jsMediaEngineVariables.localVideoDecMGR,
      VideoDec_Listener,
      WORKER_TYPE.VIDEO_DECODE,
      sdkInstance
    );
  }
}

async function Video_Encode_Post_message(sdkInstance) {
  if (!jsMediaEngineVariables.localVideoEncMGR) {
    return;
  }

  await jsMediaEngineVariables.videoInitInstance.wasmSuccessPromise;
  if (!AssertMediaSdkNotDestory(sdkInstance)) {
    return;
  }

  var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(
    jsMediaEngineVariables.SPECIAL_ID
  );
  if (handle && !jsMediaEngineVariables.isVideoEncodePostStart) {
    jsMediaEngineVariables.isVideoEncodePostStart = true;
    var mtu_size = 0;
    if (util.browserType.browser === 'firefox') {
      mtu_size = 1080;
    } else {
      mtu_size = 1070;
    }
    jsMediaEngineVariables.mediaSDKHandle.mtu_size = mtu_size;
    let iv = null;
    if (jsMediaEngineVariables.ivObj) {
      iv = jsMediaEngineVariables.ivObj[WORKER_TYPE.VIDEO_ENCODE];
      // if modify {@link util.stringSplitByComma2Buffer),
      // must also modify {@link util.buffer2stringSplitByComma}
      iv = util.stringSplitByComma2Buffer(iv);
    }
    let message = {
      command: 'startMedia',
      _id: sdkInstance._id,
      websocket_ip_address:
        jsMediaEngineVariables.Video_WebSocket_Ip_Address + '&mode=2',
      confId: jsMediaEngineVariables.localVideoPara.confId,
      confKey: '',
      logon: jsMediaEngineVariables.localVideoPara.logon,
      sendvideo: true,
      isChromeOrEdge: util.browser.isChrome,
      mtu_size: mtu_size,
      meetingid: jsMediaEngineVariables.localVideoPara.meetingid,
      meetingnumb: jsMediaEngineVariables.localVideoPara.meetingnumb,
      videoencodethreadnumb:
        jsMediaEngineVariables.localVideoPara.videoencodethreadnumb,
      iv,
      videodecodethreadnumb:
        jsMediaEngineVariables.localVideoPara.videodecodethreadnumb,
      isSupportMultiThread:
        jsMediaEngineVariables.localVideoPara.isSupportMultiThread,
      qoson: jsMediaEngineVariables.localVideoPara.qoson,
      isSupportVirtualBackground:
        jsMediaEngineVariables.localVideoPara.isSupportVirtualBackground,
      uplimit: jsMediaEngineVariables.uplimit,
      isSupportWebCodecEnocde:
        jsMediaEngineVariables.localVideoPara.isSupportWebCodecEnocde,
      initWebCodecFlag: jsMediaEngineVariables.localVideoPara.initWebCodecFlag,
      is360penablehw: jsMediaEngineVariables.localVideoPara.is360penablehw,
      enable720p: jsMediaEngineVariables.localVideoPara.enable720p,
      enableReplaceCanvasWhenContextLost:
        jsMediaEngineVariables.enableReplaceCanvasWhenContextLost,
      isPreviewMode: jsMediaEngineVariables.isPreviewMode.videoEncode,
      ...(jsMediaEngineVariables.Video_Web_Transport_Ip_Address
        ? {
            webtransportURL:
              jsMediaEngineVariables.Video_Web_Transport_Ip_Address + '&mode=2',
          }
        : {}),
      enableAudioBridge: jsMediaEngineVariables.enableAudioBridge,
      platformType: jsMediaEngineVariables.localVideoPara.platformType,
      graphicalname: util.graphicName,
      vendorname: util.graphicvendorname,
      enableMultiDecodeVideoWithoutSAB:
        !!jsMediaEngineVariables.enableMultiDecodeVideoWithoutSAB,
      IsRenderInWorker: jsMediaEngineVariables.localVideoPara.IsRenderInWorker,
    };
    handle.postMessage(message);
    if (jsMediaEngineVariables.tfjsurl) {
      handle.postMessage({
        command: 'DOWNLOAD_JSON_FROM_MAIN_THREAD_OK',
        data: jsMediaEngineVariables.tfjsurl,
        type: 'js',
      });
    }

    if (jsMediaEngineVariables.vbbin && jsMediaEngineVariables.vbjson) {
      handle.postMessage({
        command: 'DOWNLOAD_JSON_FROM_MAIN_THREAD_OK',
        data: {
          bin: jsMediaEngineVariables.vbbin,
          json: jsMediaEngineVariables.vbjson,
        },
        index: 0,
      });
    }

    if (jsMediaEngineVariables.afnbin && jsMediaEngineVariables.afnjson) {
      handle.postMessage({
        command: 'DOWNLOAD_JSON_FROM_MAIN_THREAD_OK',
        data: {
          bin: jsMediaEngineVariables.afnbin,
          json: jsMediaEngineVariables.afnjson,
        },
        index: 1,
      });

      if (jsMediaEngineVariables.basebin && jsMediaEngineVariables.basejson) {
        handle.postMessage({
          command: 'DOWNLOAD_JSON_FROM_MAIN_THREAD_OK',
          data: {
            bin: jsMediaEngineVariables.basebin,
            json: jsMediaEngineVariables.basejson,
          },
          index: 2,
        });
      }
    }
  }
}

async function Add_Video_Encode_Thread(
  ssrc,
  ip_address,
  sdkInstance,
  workerParameters
) {
  if (!jsMediaEngineVariables.localVideoEncMGR) {
    return;
  }

  if (!jsMediaEngineVariables.isVideoEncodeThreadStart) {
    jsMediaEngineVariables.isVideoEncodeThreadStart = true;
    await WorkerStart(
      workerParameters,
      jsMediaEngineVariables.localVideoEncMGR,
      VideoEnc_Listener,
      WORKER_TYPE.VIDEO_ENCODE,
      sdkInstance
    );
  }
}

function Delete_Video_Decode_Thread(ssrc) {
  ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoDecMGR) {
    var handle = jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage({
        command: 'closeMedia',
      });
      jsMediaEngineVariables.localVideoDecMGR.map.delete(ssrc);
    }
    jsMediaEngineVariables.isVideoDecodePostStart = false;
  }
}

async function Audio_Decode_Post_message(sdkInstance) {
  if (jsMediaEngineVariables.isPreviewMode.audioDecode) {
    Zoom_Monitor.add_monitor('ZISA');
    return;
  }
  if (!jsMediaEngineVariables.localAudioDecMGR) {
    globalTracingLogger.error(
      'jsMediaEngineVariables.localAudioDecMGR is null when call Audio_Decode_Post_message'
    );
    return;
  }
  var handle = jsMediaEngineVariables.localAudioDecMGR.map.get(
    jsMediaEngineVariables.SPECIAL_ID
  );
  if (window['ADWF']) {
    handle.postMessage({ command: 'RIWM' });
  }

  await jsMediaEngineVariables.audioDecInitInstance.wasmSuccessPromise;
  if (!AssertMediaSdkNotDestory(sdkInstance)) {
    return true;
  }
  if (handle && !jsMediaEngineVariables.isAudioDecodePostStart) {
    jsMediaEngineVariables.isAudioDecodePostStart = true;

    let message = {
      command: 'startMedia',
      _id: sdkInstance._id,
      websocket_ip_address:
        jsMediaEngineVariables.Audio_WebSocket_Ip_Address + '&mode=5',
      sampleRate: jsMediaEngineVariables.localAudioPara.sampleRate,
      userid: jsMediaEngineVariables.localAudioPara.userid,
      logon: jsMediaEngineVariables.localAudioPara.logon,
      decode: 1,
      meetingid: jsMediaEngineVariables.localAudioPara.meetingid,
      meetingnumb: jsMediaEngineVariables.localAudioPara.meetingnumb,
      videodecodethreadnumb:
        jsMediaEngineVariables.localAudioPara.videodecodethreadnumb,
      isSupportMultiThread:
        jsMediaEngineVariables.localAudioPara.isSupportMultiThread,
      qoson: jsMediaEngineVariables.localAudioPara.qoson,
      decoderInWorklet: jsMediaEngineVariables.decoderinworklet,
      audioDecodeChannelsNum: util.isSupportPlayStereo() ? 2 : 1,
      // ZOOM-263814 firefox sharedarraybuffer support.
      shouldNotChangeSampleRate:
        util.browser.isFirefox || util.browser.isSafari,
      ...(jsMediaEngineVariables.Audio_Web_Transport_Ip_Address
        ? {
            webtransportURL:
              jsMediaEngineVariables.Audio_Web_Transport_Ip_Address + '&mode=1',
          }
        : {}),
    };
    Zoom_Monitor.add_monitor('ADSM');
    handle.postMessage(message);

    if (jsMediaEngineVariables.sharedBuffer) {
      log('post audioDec sab');
      try {
        // Try new API (clone)
        handle.postMessage({
          command: 'sharedBuffer',
          data: jsMediaEngineVariables.sharedBuffer,
        });
      } catch (e) {
        // Fall back to old API (transfer)
        handle.postMessage(
          {
            command: 'sharedBuffer',
            data: jsMediaEngineVariables.sharedBuffer,
          },
          [jsMediaEngineVariables.sharedBuffer]
        );
      }
    }
  } else {
    Zoom_Monitor.add_monitor(
      'ADSME' + !!handle + !!jsMediaEngineVariables.isAudioDecodePostStart
    );
  }
}

export async function Add_Audio_Decode_Thread(
  ssrc,
  user_id,
  sdkInstance,
  workerParameters
) {
  Zoom_Monitor.add_monitor('AADT');
  if (sdkInstance && sdkInstance.isDestroy) {
    Zoom_Monitor.add_monitor('ZID');
    return log(
      `WorkerType:audioDec;The relative SDK instance is destroy, don't start relative worker, avoid multiple same workers. `
    );
  }

  if (!jsMediaEngineVariables.isAudioDecodeThreadStart) {
    jsMediaEngineVariables.isAudioDecodeThreadStart = true;
    await WorkerStart(
      workerParameters,
      jsMediaEngineVariables.localAudioDecMGR,
      AudioDec_Listener,
      WORKER_TYPE.AUDIO_DECODE,
      sdkInstance
    );
  }
}

export function Get_SSRC_Latest_Time(ssrc) {
  ssrc = ssrc >> 10;
  if (jsMediaEngineVariables.localAudioDecMGR) {
    var localtime =
      jsMediaEngineVariables.localAudioDecMGR.GetSSRCTimeMap(ssrc);
    if (localtime === null) {
      return 0;
    } else {
      return localtime;
    }
  }
}
export function Get_Video_SSRC_Latest_Time(ssrc) {
  return {
    currentSSRCTime: jsMediaEngineVariables.CurrentSSRCTime,
    audioPlayTime: jsMediaEngineVariables.audioPlayTime,
  };
}
function Delete_Audio_Decode_Thread(ssrc) {
  ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localAudioDecMGR) {
    var handle = jsMediaEngineVariables.localAudioDecMGR.map.get(
      jsMediaEngineVariables.SPECIAL_ID
    );
    if (handle) {
      handle.postMessage({
        command: 'closeMedia', //websocket keepalive, keep handle,
      });
      jsMediaEngineVariables.localAudioDecMGR.map.delete(ssrc);
    }
    jsMediaEngineVariables.isAudioDecodePostStart = false;
  }
}

export function Meeting_Fail_Over(
  audio_websocket_address,
  video_websocket_address
) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;

  var handle = jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
  handle.postMessage({
    command: 'failover',
    websocket_ip_address: video_websocket_address,
  });
  handle = jsMediaEngineVariables.localAudioDecMGR.map.get(ssrc);
  handle.postMessage({
    command: 'failover',
    websocket_ip_address: audio_websocket_address,
  });
}

/**
 * @constructor
 */
async function Audio_Encode_Post_message(sdkInstance) {
  if (!jsMediaEngineVariables.localAudioEncMGR) {
    globalTracingLogger.error(
      'jsMediaEngineVariables.localAudioEncMGR is null when call Audio_Encode_Post_message'
    );
    return;
  }
  var handle = jsMediaEngineVariables.localAudioEncMGR.map.get(
    jsMediaEngineVariables.SPECIAL_ID
  );
  if (window['AEWF']) {
    handle.postMessage({ command: 'RIWM' });
  }
  await jsMediaEngineVariables.audioEncodeInitInstance.wasmSuccessPromise;

  if (!AssertMediaSdkNotDestory(sdkInstance)) {
    return false;
  }
  if (handle && !jsMediaEngineVariables.isAudioEncodePostStart) {
    jsMediaEngineVariables.isAudioEncodePostStart = true;

    let iv = null;
    if (jsMediaEngineVariables.ivObj) {
      iv = jsMediaEngineVariables.ivObj[WORKER_TYPE.AUDIO_ENCODE];
      // if modify {@link util.stringSplitByComma2Buffer),
      // must also modify {@link util.buffer2stringSplitByComma}
      iv = util.stringSplitByComma2Buffer(iv);
    }

    let message = {
      command: 'startMedia',
      _id: sdkInstance._id,
      websocket_ip_address:
        jsMediaEngineVariables.Audio_WebSocket_Ip_Address + '&mode=2',
      sampleRate: jsMediaEngineVariables.localAudioPara.sampleRate,
      userid: jsMediaEngineVariables.localAudioPara.userid,
      encode: 1,
      meetingid: jsMediaEngineVariables.localAudioPara.meetingid,
      meetingnumb: jsMediaEngineVariables.localAudioPara.meetingnumb,
      iv,
      qoson: jsMediaEngineVariables.localAudioPara.qoson,
      // ZOOM-263814 firefox sharedarraybuffer support.
      shouldNotChangeSampleRate:
        util.browser.isFirefox || util.browser.isSafari,
      isPreviewMode: jsMediaEngineVariables.isPreviewMode.audioEncode,
      audioEncodeChannelsNum: util.isSupportMicSendStereo() ? 2 : 1,
      ...(jsMediaEngineVariables.Audio_Web_Transport_Ip_Address
        ? {
            webtransportURL:
              jsMediaEngineVariables.Audio_Web_Transport_Ip_Address + '&mode=2',
          }
        : {}),
    };
    handle.postMessage(message);

    if (jsMediaEngineVariables.sharedBuffer) {
      log('post audioEnc sab');
      try {
        // Try new API (clone)
        handle.postMessage({
          command: 'sharedBuffer',
          data: jsMediaEngineVariables.sharedBuffer,
        });
      } catch (e) {
        // Fall back to old API (transfer)
        handle.postMessage(
          {
            command: 'sharedBuffer',
            data: jsMediaEngineVariables.sharedBuffer,
          },
          [jsMediaEngineVariables.sharedBuffer]
        );
      }
    }
  }
}

async function Add_Audio_Encode_Thread(ssrc, sdkInstance, workerParameters) {
  if (!jsMediaEngineVariables.isAudioEncodeThreadStart) {
    jsMediaEngineVariables.isAudioEncodeThreadStart = true;
    await WorkerStart(
      workerParameters,
      jsMediaEngineVariables.localAudioEncMGR,
      AudioEnc_Listener,
      WORKER_TYPE.AUDIO_ENCODE,
      sdkInstance
    );
  }
}

export function Modify_Audio_SampleRate(sampleRate) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  var data = jsMediaEngineVariables.audio_pcm_queue.dequeue();
  while (data) {
    data = jsMediaEngineVariables.audio_pcm_queue.dequeue();
  }
  var handle;
  if (jsMediaEngineVariables.localAudioEncMGR) {
    handle = jsMediaEngineVariables.localAudioEncMGR.map.get(ssrc);
    if (handle) {
      if (jsMediaEngineVariables.isAudioEncodePostStart) {
        handle.postMessage({
          command: 'modifySampleRate',
          sampleRate: sampleRate,
        });
      }
    }
  }
  if (jsMediaEngineVariables.localAudioDecMGR) {
    handle = jsMediaEngineVariables.localAudioDecMGR.map.get(ssrc);
    if (handle) {
      if (jsMediaEngineVariables.isAudioDecodePostStart) {
        handle.postMessage({
          command: 'modifySampleRate',
          sampleRate: sampleRate,
        });
      }
    }
  }
}

function Delete_Audio_Encode_Thread(ssrc) {
  ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localAudioEncMGR) {
    var handle = jsMediaEngineVariables.localAudioEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage({
        command: 'closeMedia',
      });
      jsMediaEngineVariables.localAudioEncMGR.map.delete(ssrc);
    }
    jsMediaEngineVariables.isAudioEncodePostStart = false;
  }
}

export function Notify_Audio_Thread_Status(ssrc, status, fakeLeave) {
  ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localAudioDecMGR) {
    var handle = jsMediaEngineVariables.localAudioDecMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage({
        command: 'mute',
        bOn: status,
        fakeLeave,
      });
    }
  }
  if (jsMediaEngineVariables.localAudioEncMGR) {
    var handle = jsMediaEngineVariables.localAudioEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage({
        command: 'mute',
        bOn: status,
        fakeLeave,
      });
    }
  }
}

export function Notify_Audio_Thread_AEC_Status(ssrc, status) {
  ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localAudioDecMGR) {
    var handle = jsMediaEngineVariables.localAudioDecMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage({
        command: 'AecFlag',
        flag: status,
      });
    }
  }
  if (jsMediaEngineVariables.localAudioEncMGR) {
    var handle = jsMediaEngineVariables.localAudioEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage({
        command: 'AecFlag',
        flag: status,
      });
    }
  }
}

export function Notify_Audio_Thread_Msg_Channel(decode, encode) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localAudioDecMGR) {
    var handle = jsMediaEngineVariables.localAudioDecMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(
        {
          command: 'decodeAudioPort',
        },
        [decode.port2]
      );
    }
  }
  if (jsMediaEngineVariables.localAudioEncMGR) {
    var handle = jsMediaEngineVariables.localAudioEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(
        {
          command: 'encodeAudioPort',
        },
        [encode.port1]
      );
    }
  }
}

export function Notify_Video_Thread_Msg_Channel(decode, encode) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoDecMGR) {
    let handle = jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(
        {
          command: 'decodeVideoPort',
        },
        [decode.port1]
      );
    }
  }
  if (jsMediaEngineVariables.localVideoEncMGR) {
    let handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(
        {
          command: 'encodeVideoPort',
        },
        [encode.port2]
      );
    }
  }
}

export function Notify_Video_Sharing_Msg_Channel(sender, receiver) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoDecMGR) {
    let handle = jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(
        {
          command: 'vsport',
        },
        [sender.port1]
      );
    }
  }
  if (jsMediaEngineVariables.localSharingDecMGR) {
    let handle = jsMediaEngineVariables.localSharingDecMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(
        {
          command: 'vsport',
        },
        [receiver.port2]
      );
    }
  }
}

export function Notify_Audio_Thread_Msg_Channel2(decode) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localAudioDecMGR) {
    var handle = jsMediaEngineVariables.localAudioDecMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(
        {
          command: 'decodeAudioPort2',
        },
        [decode.port2]
      );
    }
  }
}

export function Notify_Audio_Thread_Msg_Channel3(decode) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localAudioEncMGR) {
    var handle = jsMediaEngineVariables.localAudioEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(
        {
          command: 'shareAudioDecodeAudioPort2',
        },
        [decode.port2]
      );
    }
  }
}

export function Notify_Audio_Video_Thread_Msg_Channel(decode) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoDecMGR) {
    var handle = jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(
        {
          command: 'decodeVideoPortWithAudio',
          port: decode.port1,
        },
        [decode.port1]
      );
    }
  }
}

export function Notify_Video_Encode_Thread(para) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoEncMGR) {
    var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(para);
    }
  }
}

export function Notify_Video_Encode_Thread_Transferable_Data(command, data) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoEncMGR) {
    var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage({ command: command, data: data }, [data]);
    }
  }
}

export function Notify_Video_Decode_Thread(para) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoDecMGR) {
    var handle = jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(para);
    }
  }
}
export function Notify_Sharing_Video_Encode_Thread_Transferable_Data(
  command,
  data
) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoEncMGR) {
    var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage({ command: command, data: data }, [data]);
    }
  }
}

export function Notify_Sharing_Encode_Thread_Transferable_Data(command, data) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localSharingEncMGR) {
    var handle = jsMediaEngineVariables.localSharingEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage({ command: command, data: data }, [data]);
    }
  }
}

export function Notify_Sharing_Video_Decode_Thread(value) {
  if (!jsMediaEngineVariables.localVideoDecMGR) {
    return;
  }
  let mgr = jsMediaEngineVariables.localVideoDecMGR;
  var handle = mgr.map.get(jsMediaEngineVariables.SPECIAL_ID);
  if (handle) {
    handle.postMessage(value);
  }
}

export function Notify_Sharing_Decode_Thread(para) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localSharingDecMGR) {
    var handle = jsMediaEngineVariables.localSharingDecMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(para);
    }
  }
}
export function Notify_Audio_Thread_CurrentSSRC(currentSSRC) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localAudioDecMGR) {
    var handle = jsMediaEngineVariables.localAudioDecMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage({
        command: 'updateCurrentSSRC',
        SSRC: currentSSRC,
      });
    }
  }
}

export function Notify_Audio_Encode_Thread(para) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localAudioEncMGR) {
    var handle = jsMediaEngineVariables.localAudioEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(para);
    }
  }
}

export function Notify_Audio_Decode_Thread(para) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localAudioDecMGR) {
    var handle = jsMediaEngineVariables.localAudioDecMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(para);
    }
  }
}

export function Notify_Audio_Thread_Interpretation_Enable(enable) {
  var param = {
    command: 'interpretation_enable',
    enable: enable,
  };

  Notify_Audio_Encode_Thread(param);
  if (jsMediaEngineVariables.decoderinworklet) {
    if (jsMediaEngineVariables.workletWasmInitSuccess) {
      jsMediaEngineVariables.AudioNode.postCMD('interpretation_enable', param);
    } else {
      //join as attendees, this message will be sent when click join computer audio
      //join in meeting or panelists, this message will be sent after capture audio success
      jsMediaEngineVariables.interpretationMessage.enqueue(param);
    }
  } else {
    Notify_Audio_Decode_Thread(param);
  }
}

/** set lang for CC */
export function Notify_Audio_Thread_CC_Set_Lang(lang) {
  var param = {
    command: 'cc_set_lang',
    lang: lang,
  };
  Notify_Audio_Encode_Thread(param);
}

export function Notify_Audio_Thread_Interpretation_Set_Lang(lang) {
  var param = {
    command: 'interpretation_set_lang',
    lang: lang,
  };

  Notify_Audio_Encode_Thread(param);
  if (jsMediaEngineVariables.decoderinworklet) {
    if (jsMediaEngineVariables.workletWasmInitSuccess) {
      jsMediaEngineVariables.AudioNode.postCMD(
        'interpretation_set_lang',
        param
      );
    } else {
      jsMediaEngineVariables.interpretationMessage.enqueue(param);
    }
  } else {
    Notify_Audio_Decode_Thread(param);
  }
}

export function Notify_Audio_Thread_Interpretation_Mute(mute) {
  var param = {
    command: 'interpretation_mute_origin',
    mute: mute,
  };

  Notify_Audio_Encode_Thread(param);
  if (jsMediaEngineVariables.decoderinworklet) {
    if (jsMediaEngineVariables.workletWasmInitSuccess) {
      jsMediaEngineVariables.AudioNode.postCMD(
        'interpretation_mute_origin',
        param
      );
    } else {
      jsMediaEngineVariables.interpretationMessage.enqueue(param);
    }
  } else {
    Notify_Audio_Decode_Thread(param);
  }
}

export function Notify_Audio_Thread_Interpretation_Set_Interpreter(list) {
  var param = {
    command: 'interpretation_set_interpreter',
    interpreterList: list,
  };

  Notify_Audio_Encode_Thread(param);
  if (jsMediaEngineVariables.decoderinworklet) {
    if (jsMediaEngineVariables.workletWasmInitSuccess) {
      jsMediaEngineVariables.AudioNode.postCMD(
        'interpretation_set_interpreter',
        param
      );
    } else {
      jsMediaEngineVariables.interpretationMessage.enqueue(param);
    }
  } else {
    Notify_Audio_Decode_Thread(param);
  }
}

export function Reset_Aec() {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localAudioEncMGR) {
    var handle = jsMediaEngineVariables.localAudioEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage({
        command: 'resetAec',
      });
    }
  }
}

export function Set_Aec_Delay() {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  var delay = 20;
  if (jsMediaEngineVariables.audioDelay) {
    delay = jsMediaEngineVariables.audioDelay;
  }
  if (jsMediaEngineVariables.localAudioEncMGR) {
    var handle = jsMediaEngineVariables.localAudioEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage({
        command: 'SetAECDelay',
        delay: delay,
      });
    }
  }
}

function Monitor_Slim(log, numb) {
  if (numb <= 0) {
    return log;
  }
  var index = 0;
  for (; index < numb; index++) {
    log += ',';
  }
  return log;
}

function _listenWindowErrorEvent(ev) {
  try {
    if (Zoom_Monitor && ev) {
      let logRawData = ['ERR:', ev.message, 'f'].join('');
      let returnObj =
        Zoom_Monitor.checkIsNecessaryExceptionLogAndReturnRepeatTimes(
          logRawData
        );
      if (returnObj.isNecessary) {
        log(`error repeat ${returnObj.repeatNumber}`, logRawData);
        Zoom_Monitor.add_monitor(
          logRawData + `(repeat:${returnObj.repeatNumber})`
        );
      } else {
        log('error but ignore', ev.message);
      }
    }
  } catch (ex) {
    log('_listenWindowErrorEvent error', ex);
  }
}

function listenWindowErrorEvent() {
  window.addEventListener('error', _listenWindowErrorEvent);
}

function removeWindowErrorEventListener() {
  window.removeEventListener('error', _listenWindowErrorEvent);
}

function saveBrowserInfo() {
  try {
    Zoom_Monitor.add_monitor(['BSAGT:', navigator.userAgent].join(''));
    Zoom_Monitor.add_monitor('BSLITEND:' + (util.isLittleEndian() ? 1 : 0));

    if (window.navigator.hardwareConcurrency) {
      Zoom_Monitor.add_monitor(
        'OSCPUS:' + window.navigator.hardwareConcurrency
      );
    }
  } catch (ex) {}
}

function sendMonitorLog(data) {
  jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
    evt: 4167,
    seq: 1,
    body: {
      data: data,
    },
  });
}

export function Start_Monitor(sdkInstance) {
  Zoom_Monitor.init();
  listenWindowErrorEvent();
  saveBrowserInfo();
  jsMediaEngineVariables.monitorIntervalHandle = setInterval(function () {
    let zm = Zoom_Monitor.get_monitor();
    if (zm) {
      sendMonitorLog(zm);
    }

    if (
      jsMediaEngineVariables.monitorEncodeVideo == null &&
      jsMediaEngineVariables.monitorDecodeVideo == null &&
      jsMediaEngineVariables.monitorVideoCapture == null &&
      jsMediaEngineVariables.monitorEncodeSharing == null &&
      jsMediaEngineVariables.monitorDecodeSharing == null &&
      jsMediaEngineVariables.monitorSharingEncodeAPPInfo == null &&
      jsMediaEngineVariables.monitorVideoDecodeAPPInfo == null &&
      jsMediaEngineVariables.monitorVideoEncodeAPPInfo == null
    ) {
      return;
    }
    var monitor_buffer;

    {
      //monitorVideoCapture
      if (jsMediaEngineVariables.monitorVideoCapture != null) {
        monitor_buffer =
          'WCL_CAMERA,' +
          jsMediaEngineVariables.monitorVideoUserID +
          ',0' +
          ',' +
          ',' +
          jsMediaEngineVariables.monitorVideoCapture +
          ',' +
          ',' +
          jsMediaEngineVariables.monitorVideoReadyCaptureWidth +
          ',' +
          jsMediaEngineVariables.monitorVideoReadyCaptureHeight;
        monitor_buffer = Monitor_Slim(monitor_buffer, 3);
        monitor_buffer = monitor_buffer + ',{[END]}';
        sendMonitorLog(monitor_buffer);
        jsMediaEngineVariables.monitorVideoCapture = null;
      }
    }

    if (jsMediaEngineVariables.monitorSharingEncodeAPPInfo != null) {
      sendMonitorLog(jsMediaEngineVariables.monitorSharingEncodeAPPInfo.data);
      jsMediaEngineVariables.monitorSharingEncodeAPPInfo = null;
    }
    if (jsMediaEngineVariables.monitorVideoDecodeAPPInfo != null) {
      sendMonitorLog(jsMediaEngineVariables.monitorVideoDecodeAPPInfo.data);
      jsMediaEngineVariables.monitorVideoDecodeAPPInfo = null;
    }
    if (jsMediaEngineVariables.monitorVideoEncodeAPPInfo != null) {
      sendMonitorLog(jsMediaEngineVariables.monitorVideoEncodeAPPInfo.data);
      jsMediaEngineVariables.monitorVideoEncodeAPPInfo = null;
    }
  }, 1000 * 10);
}

export function Send_Render_Monitor_Log(log) {
  Zoom_Monitor.add_monitor2(log);
}
export function Stop_Monitor(sdkInstance) {
  removeWindowErrorEventListener();
  clearInterval(jsMediaEngineVariables.monitorIntervalHandle);
  Zoom_Monitor.send_instant_monitor();
}

export function Update_Video_Encrpt(value) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoEncMGR) {
    var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage({
        command: 'ENCRYPT',
        encrypt: value,
      });
    }
  }
}

export function Update_Sharing_Encrpt(value) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localSharingEncMGR) {
    var handle = jsMediaEngineVariables.localSharingEncMGR.Get(ssrc);
    if (handle) {
      handle.postMessage({
        command: 'ENCRYPT',
        encrypt: value,
      });
    }
  }
}

export function Update_Sharing_Video_Encode_Status(value) {
  if (!jsMediaEngineVariables.localVideoEncMGR) {
    return;
  }
  let mgr = jsMediaEngineVariables.localVideoEncMGR;
  var handle = mgr.map.get(jsMediaEngineVariables.SPECIAL_ID);
  if (handle) {
    handle.postMessage(value);
  }
}

export function Update_Sharing_Encode_Status(value) {
  if (!jsMediaEngineVariables.localSharingEncMGR) {
    return;
  }
  let mgr = jsMediaEngineVariables.localSharingEncMGR;
  var handle = mgr.map.get(jsMediaEngineVariables.SPECIAL_ID);
  if (handle) {
    handle.postMessage(value);
  }
}

export function Notify_Sharing_Video_Encode_Thread(value) {
  if (!jsMediaEngineVariables.localVideoEncMGR) {
    return;
  }
  let mgr = jsMediaEngineVariables.localVideoEncMGR;
  var handle = mgr.map.get(jsMediaEngineVariables.SPECIAL_ID);
  if (handle) {
    handle.postMessage(value);
  }
}

export function Notify_Sharing_Encode_Thread(value) {
  if (!jsMediaEngineVariables.localSharingEncMGR) {
    return;
  }
  let mgr = jsMediaEngineVariables.localSharingEncMGR;
  var handle = mgr.map.get(jsMediaEngineVariables.SPECIAL_ID);
  if (handle) {
    handle.postMessage(value);
  }
}

export function Update_Audio_Encrpt(value) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localAudioEncMGR) {
    var handle = jsMediaEngineVariables.localAudioEncMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage({
        command: 'ENCRYPT',
        encrypt: value,
      });
    }
  }
}

export function disableSocketReconnect() {
  let id = jsMediaEngineVariables.SPECIAL_ID;
  let handlerList = [
    jsMediaEngineVariables.localAudioDecMGR,
    jsMediaEngineVariables.localAudioEncMGR,
    jsMediaEngineVariables.localVideoDecMGR,
    jsMediaEngineVariables.localVideoEncMGR,
    jsMediaEngineVariables.localSharingDecMGR,
    jsMediaEngineVariables.localSharingEncMGR,
  ];

  handlerList.forEach((item) => {
    if (item && item.map.get(id)) {
      let handle = item.map.get(id);
      handle.postMessage({
        command: 'SOCKET_RECONNECT',
        disable: true,
      });
    }
  });
}

export function closeAllPromiseObject(instances) {
  instances.forEach((elm) => {
    if (elm) {
      elm.setHanderSuccess(false);
      elm.setSocketSuccess(false);
      elm.setWasmSuccess(false);
    }
  });
}

export function clearWorkerListener(videohandle, audiohandle) {
  let id = jsMediaEngineVariables.SPECIAL_ID;
  if (videohandle) {
    videohandle.forEach((item) => {
      if (item && item.map.get(id)) {
        let handle = item.map.get(id);
        handle.onmessage = null;
      }
    });
  }

  if (audiohandle) {
    audiohandle.forEach((item) => {
      if (item && item.map.get(id)) {
        let handle = item.map.get(id);
        handle.onmessage = null;
      }
    });
  }
}

export async function closeAllMedia(
  sdkInstance,
  videohandlelist,
  audiohandlelist
) {
  let id = jsMediaEngineVariables.SPECIAL_ID;
  let handlerList = videohandlelist;

  handlerList.forEach((item) => {
    if (item && item.map.get(id)) {
      let handle = item.map.get(id);
      handle.postMessage({ command: 'closeMedia', _id: sdkInstance._id });
    }
  });

  handlerList = audiohandlelist;
  handlerList.forEach((item) => {
    if (item && item.map.get(id)) {
      let handle = item.map.get(id);
      handle.postMessage({ command: 'closeMedia', _id: sdkInstance._id });
    }
  });

  await util.sleep(1000);
}

export async function destroyAllWorkers(videohandle, audiohandle) {
  let id = jsMediaEngineVariables.SPECIAL_ID;

  let handlerList = videohandle;
  handlerList.forEach((item) => {
    if (item && item.map.get(id)) {
      let handle = item.map.get(id);
      handle.terminate();
    }
  });

  handlerList = audiohandle;
  if (!(await util.is32bitChrome())) {
    handlerList.forEach((item) => {
      if (item && item.map.get(id)) {
        let handle = item.map.get(id);
        handle.terminate();
      }
    });
  }
}

/**
 * @param workerType {WORKER_TYPE}
 * @returns worker {Worker} - instanse or null
 */
function getHandle(workerType) {
  let mgr;
  switch (workerType) {
    case WORKER_TYPE.AUDIO_DECODE:
      mgr = jsMediaEngineVariables.localAudioDecMGR;
      break;
    case WORKER_TYPE.AUDIO_ENCODE:
      mgr = jsMediaEngineVariables.localAudioEncMGR;
      break;
    case WORKER_TYPE.VIDEO_DECODE:
      mgr = jsMediaEngineVariables.localVideoDecMGR;
      break;
    case WORKER_TYPE.VIDEO_ENCODE:
      mgr = jsMediaEngineVariables.localVideoEncMGR;
      break;
    case WORKER_TYPE.SHARING_DECODE:
      mgr = jsMediaEngineVariables.localSharingDecMGR;
      break;
    case WORKER_TYPE.SHARING_ENCODE:
      mgr = jsMediaEngineVariables.localSharingEncMGR;
      break;
  }

  let id = jsMediaEngineVariables.SPECIAL_ID;
  if (mgr && mgr.map.get(id)) {
    return mgr.map.get(id);
  } else {
    return null;
  }
}

/**
 * @param workerType {WORKER_TYPE}
 */
async function onWorkerInitOK(workerType) {
  switch (workerType) {
    case WORKER_TYPE.VIDEO_ENCODE:
      await jsMediaEngineVariables.videoInitInstance.initSuccessPromise;
      break;
    case WORKER_TYPE.VIDEO_DECODE:
      await jsMediaEngineVariables.videoDecInitInstance.initSuccessPromise;
      break;
    case WORKER_TYPE.AUDIO_ENCODE:
      await jsMediaEngineVariables.audioEncodeInitInstance.initSuccessPromise;
      break;
    case WORKER_TYPE.AUDIO_DECODE:
      await jsMediaEngineVariables.audioDecInitInstance.initSuccessPromise;
      break;
    case WORKER_TYPE.SHARING_ENCODE:
      await jsMediaEngineVariables.sharingEncInitInstance.initSuccessPromise;
      break;
    case WORKER_TYPE.SHARING_DECODE:
      await jsMediaEngineVariables.sharingDecInitInstance.initSuccessPromise;
      break;
    default:
      globalTracingLogger.error(`No matched worker type: ${workerType}`);
      break;
  }
}
/**
 * @param workerType
 * @param message
 * @param command
 * @param transfer
 * @returns {Promise<Boolean>}
 */
export async function pushMessageToWorker(
  workerType,
  message,
  command = 'PUSH_MESSAGE_FROM_MAIN_TO_WORKER',
  transfer = false,
  isWaitForWorkerOK = false
) {
  if (isWaitForWorkerOK) {
    await onWorkerInitOK(workerType);
  }
  let handle = getHandle(workerType);

  if (!handle) {
    if (!isWaitForWorkerOK) {
      log('worker handle not ready, drop!', workerType, message, command);
      return false;
    } else {
      log.warn(
        'worker handle not ready, waiting!',
        workerType,
        message,
        command
      );
      // 2020.12.24 remove setInterval detect handler existed, instead use promise, it's more efficient and
      // detection timeouts are also not a problem on some low performance machines (eg: some chromebooks initialization is very slow)

      handle = getHandle(workerType);
      if (!handle) {
        return;
      }
    }
  }

  if (!transfer) {
    handle.postMessage({
      command,
      data: message,
    });
  } else {
    handle.postMessage(
      {
        command,
        data: message,
      },
      [message]
    );
  }

  return true;
}

export function isVideoEncodeHandleReady() {
  return getHandle(WORKER_TYPE.VIDEO_ENCODE) !== null;
}

export function transportSettingCanvas(canvas, cmd, canvasId) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoEncMGR) {
    var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
    if (handle) {
      if (canvas) {
        handle.postMessage(
          {
            command: cmd,
            canvas: canvas,
            rendercanvasID: canvasId,
          },
          [canvas]
        );
      } else {
        handle.postMessage({
          command: cmd,
          canvas: canvas,
          rendercanvasID: canvasId,
        });
      }
    }
  }
}

export function initResourceManager(IResourceManager) {
  let resourceManager;
  resourceManager = new IResourceManager({
    storeOptions: {
      nameSpaceId: 'vbFile',
    },
  });
  return new Promise((resolve, reject) => {
    resourceManager.on('ready', () => {
      resolve(resourceManager);
    });
  });
}

function DecryptDualModel(dualModel) {
  let iv = new Uint8Array(dualModel, 0, 12);
  let key = new Uint8Array(dualModel, 12, 32);
  window.crypto.subtle
    .importKey('raw', key, 'AES-GCM', true, ['encrypt', 'decrypt'])
    .then((keyAES) => {
      let offset = iv.length + key.length;

      let length = new Uint32Array(dualModel.slice(offset, offset + 4))[0];
      offset = offset + 4;
      let base_model_bin = dualModel.slice(offset, offset + length);
      offset = offset + length;

      length = new Uint32Array(dualModel.slice(offset, offset + 4))[0];
      offset = offset + 4;
      let base_model_json = new Uint8Array(dualModel, offset, length);
      offset = offset + length;

      length = new Uint32Array(dualModel.slice(offset, offset + 4))[0];
      offset = offset + 4;
      let afn_model_bin = dualModel.slice(offset, offset + length);
      offset = offset + length;

      length = new Uint32Array(dualModel.slice(offset, offset + 4))[0];
      offset = offset + 4;
      let afn_model_json = new Uint8Array(dualModel, offset, length);

      jsMediaEngineVariables.basebin = base_model_bin;
      jsMediaEngineVariables.afnbin = afn_model_bin;

      decryptData(afn_model_json, keyAES, iv).then((afnJson) => {
        jsMediaEngineVariables.afnjson = new TextDecoder().decode(afnJson);
        var ssrc = jsMediaEngineVariables.SPECIAL_ID;
        if (jsMediaEngineVariables.localVideoEncMGR) {
          var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
          if (handle) {
            if (
              jsMediaEngineVariables.afnbin &&
              jsMediaEngineVariables.afnjson
            ) {
              handle.postMessage({
                command: 'DOWNLOAD_JSON_FROM_MAIN_THREAD_OK',
                data: {
                  bin: jsMediaEngineVariables.afnbin,
                  json: jsMediaEngineVariables.afnjson,
                },
                index: 1,
              });
            }
          }
        }
      });
      decryptData(base_model_json, keyAES, iv).then((baseJson) => {
        jsMediaEngineVariables.basejson = new TextDecoder().decode(baseJson);
        var ssrc = jsMediaEngineVariables.SPECIAL_ID;
        if (jsMediaEngineVariables.localVideoEncMGR) {
          var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
          if (handle) {
            if (
              jsMediaEngineVariables.basebin &&
              jsMediaEngineVariables.basejson
            ) {
              handle.postMessage({
                command: 'DOWNLOAD_JSON_FROM_MAIN_THREAD_OK',
                data: {
                  bin: jsMediaEngineVariables.basebin,
                  json: jsMediaEngineVariables.basejson,
                },
                index: 2,
              });
            }
          }
        }
      });
    });
}
function decryptData(encryptedData, key, iv) {
  return window.crypto.subtle.decrypt(
    { name: 'AES-GCM', iv: iv },
    key,
    encryptedData
  );
}

function StartDecrypt(buffer) {
  var ivbuffer = new Uint8Array(buffer, 0, 12);
  //  jsMediaEngineVariables.vbbin=new Uint8Array(buffer, 12,496924);
  jsMediaEngineVariables.vbbin = buffer.slice(12, 12 + 496924); //must be arraybuffer
  var keybuffer = new Uint8Array(buffer, 12 + 496924, 32);
  var jsonbuffer = new Uint8Array(buffer, 12 + 496924 + 32, 87253);
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  Decrypt(ivbuffer, keybuffer, jsonbuffer);
}

function Decrypt(ivBuffer, rawKey, decryptedMsg) {
  window.crypto.subtle
    .importKey('raw', rawKey, 'AES-GCM', true, ['encrypt', 'decrypt'])
    .then((gcmFinalKeyObj) => {
      window.crypto.subtle
        .decrypt(
          {
            name: 'AES-GCM',
            iv: ivBuffer,
          },
          gcmFinalKeyObj,
          decryptedMsg
        )
        .then((decryptedBuf) => {
          jsMediaEngineVariables.vbjson = new TextDecoder().decode(
            decryptedBuf
          );
          var ssrc = jsMediaEngineVariables.SPECIAL_ID;
          if (jsMediaEngineVariables.localVideoEncMGR) {
            var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
            if (handle) {
              if (
                jsMediaEngineVariables.vbbin &&
                jsMediaEngineVariables.vbjson
              ) {
                handle.postMessage({
                  command: 'DOWNLOAD_JSON_FROM_MAIN_THREAD_OK',
                  data: {
                    bin: jsMediaEngineVariables.vbbin,
                    json: jsMediaEngineVariables.vbjson,
                  },
                  index: 0,
                });
              }
            }
          }
        });
    });
}

export function prepareVBfile(file, IResourceManager) {
  const headers = {};
  (jsMediaEngineVariables.resourceManager
    ? Promise.resolve(jsMediaEngineVariables.resourceManager)
    : initResourceManager(IResourceManager)
  ).then((resourceManager) => {
    jsMediaEngineVariables.resourceManager = resourceManager;
    for (let i = 0; i < file.length; i++) {
      if (file[i].type == 'bin') {
        jsMediaEngineVariables.resourceManager
          .getFile(file[i].path, {
            saveRootPath: file[i].path,
            downloadOptions: {
              xhrParams: {
                headers,
                url: file[i].path,
                encoding: 'arraybuffer',
                method: 'GET',
              },
            },
          })
          .getResult()
          .then((res) => {
            Zoom_Monitor.add_monitor('VDBIN');
            jsMediaEngineVariables.vbarraybuffer = res.data;
            try {
              if (file[i].path.indexOf('dual') >= 0) {
                DecryptDualModel(res.data);
              } else {
                //StartDecrypt(jsMediaEngineVariables.vbarraybuffer);
              }
            } catch (e) {
              globalTracingLogger.error(
                'An error occurred when decrypting the virtual background file',
                e
              );
              Zoom_Monitor.add_monitor('VBDECRYPTF');
              jsMediaEngineVariables.Notify_APPUI(
                jsEvent.VIDEO_VB_SETTING_PARA_ERROR,
                4
              );
            }
          })
          .catch((e) => {
            globalTracingLogger.error(
              'An error occurred when trying to obtain the virtual background file',
              e
            );
            Zoom_Monitor.add_monitor('VBFF');
            jsMediaEngineVariables.Notify_APPUI(
              jsEvent.VIDEO_VB_SETTING_PARA_ERROR,
              4
            );
          });
      } else if (file[i].type == 'js') {
        Zoom_Monitor.add_monitor('VDTF');
        jsMediaEngineVariables.tfjsurl = file[i].path;
        var ssrc = jsMediaEngineVariables.SPECIAL_ID;
        if (jsMediaEngineVariables.localVideoEncMGR) {
          var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
          if (handle) {
            handle.postMessage({
              command: 'DOWNLOAD_JSON_FROM_MAIN_THREAD_OK',
              data: jsMediaEngineVariables.tfjsurl,
              type: 'js',
            });
          }
        }
      }
    }
  });
}

export function transportImageBitMap(data) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoEncMGR) {
    var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
    if (handle) {
      if (data) {
        handle.postMessage(
          {
            command: 'imagebitmap',
            data: data,
          },
          [data]
        );
      } else {
        handle.postMessage({
          command: 'imagebitmap',
        });
      }
    }
  }
}

export function transportBgImageBitMap(data, imagename) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoEncMGR) {
    var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
    if (handle) {
      if (data) {
        handle.postMessage(
          {
            command: 'maskandbgimagebitmap',
            imagename: imagename,
            data: data,
          },
          [data]
        );
      } else {
        handle.postMessage({
          command: 'maskandbgimagebitmap',
          imagename: imagename,
          data: data,
        });
      }
    }
  }
}

export function transportVBBgImageBitMap(data, imagename) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoEncMGR) {
    var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
    if (handle) {
      if (data) {
        handle.postMessage(
          {
            command: 'vbbgimagebitmap',
            imagename: imagename,
            data: data,
          },
          [data]
        );
      } else {
        handle.postMessage({
          command: 'vbbgimagebitmap',
          imagename: imagename,
          data: data,
        });
      }
    }
  }
}

export function transportMaskImageBitMap(
  data,
  imagename,
  maskCoordinate,
  width = 0,
  height = 0
) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoEncMGR) {
    var handle = jsMediaEngineVariables.localVideoEncMGR.map.get(ssrc);
    if (handle) {
      if (data) {
        handle.postMessage(
          {
            command: 'maskandbgimagebitmap',
            imagename: imagename,
            data: data,
            maskCoordinate: maskCoordinate,
            width: width,
            height: height,
          },
          [data]
        );
      } else {
        handle.postMessage({
          command: 'maskandbgimagebitmap',
          imagename: imagename,
          data: data,
          maskCoordinate: maskCoordinate,
          width: width,
          height: height,
        });
      }
    }
  }
}

export function MirrorSendVideo(data) {
  var ssrc = jsMediaEngineVariables.SPECIAL_ID;
  if (jsMediaEngineVariables.localVideoDecMGR) {
    var handle = jsMediaEngineVariables.localVideoDecMGR.map.get(ssrc);
    if (handle) {
      handle.postMessage(data);
    }
  }
}
export function isSharingNotClearChromeVersion() {
  try {
    // In chrome 85 && 86,  offscreencanvas has bugs, that makes sharing not clear, blink picture
    return (
      util.browser.isChrome &&
      ['85', '86'].indexOf(util.getBrowserVersion()) !== -1
    );
  } catch (e) {
    log(e);
    return false;
  }
}

/**
 * @type {{[p: string]: {success: number, callbackDataValue: number, failed: number}|{success: number, callbackDataValue: number, failed: number}|{success: number, callbackDataValue: number, failed: number}|{success: number, callbackDataValue: number, failed: number}|{success: number, callbackDataValue: number, failed: number}|{success: number, callbackDataValue: number, failed: number}}}
 */
export const MEDIA_INIT_SUCCESS_CALLBACK_METADATA = {
  [WORKER_TYPE.VIDEO_ENCODE]: {
    success: jsEvent.INIT_SUCCESS_VIDEO,
    failed: jsEvent.INIT_FAILED_VIDEO,
    callbackDataValue: EncodeDecodeEnum.encode,
  },
  [WORKER_TYPE.VIDEO_DECODE]: {
    success: jsEvent.INIT_SUCCESS_VIDEO,
    failed: jsEvent.INIT_FAILED_VIDEO,
    callbackDataValue: EncodeDecodeEnum.decode,
  },
  [WORKER_TYPE.AUDIO_ENCODE]: {
    success: jsEvent.INIT_SUCCESS_AUDIO,
    failed: jsEvent.INIT_FAILED_AUDIO,
    callbackDataValue: EncodeDecodeEnum.encode,
  },
  [WORKER_TYPE.AUDIO_DECODE]: {
    success: jsEvent.INIT_SUCCESS_AUDIO,
    failed: jsEvent.INIT_FAILED_AUDIO,
    callbackDataValue: EncodeDecodeEnum.decode,
  },
  [WORKER_TYPE.SHARING_DECODE]: {
    success: jsEvent.INIT_SUCCESS_SHARING,
    failed: jsEvent.INIT_FAILED_SHARING,
    callbackDataValue: EncodeDecodeEnum.decode,
  },
  [WORKER_TYPE.SHARING_ENCODE]: {
    success: jsEvent.INIT_SUCCESS_SHARING,
    failed: jsEvent.INIT_FAILED_SHARING,
    callbackDataValue: EncodeDecodeEnum.encode,
  },
};

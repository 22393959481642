//JSPLD JsMediaSDK_PreLoad
//JSINIT JsMediaSDK_Init
//event abbr
// https://zoomvideo.atlassian.net/wiki/spaces/ZTP/pages/451805636/ZOOM-71587+Add+trouble+shooting+main+steps+tracking+for+web+client
import * as jsEvent from '../common/jsEvent';
import jsMediaEngineVariables from './JsMediaEngine_Variables';
import globalTracingLogger, { isPowerOf2 } from '../common/globalTracingLogger';

// Descriptive names for errors that may occur. Sent as part of GlobalTracing messages.
const errorLogDescriptions = {
  AEWF: 'Audio encode WASM failed to download/compile',
  ADWF: 'Audio decode WASM failed to download/compile',
  VDWF: 'Video decode WASM failed to download/compile',
  VEWF: 'Video encode WASM failed to download/compile',
  SEWF: 'Sharing encode WASM failed to download/compile',
  SDWF: 'Sharing decode WASM failed to download/compile',
  MWCGLF: 'WebGL context failed to be restored',
  VCFF: 'Captured video format is not supported',
  SHHF: 'Initialization of sharing decode WASM failed',
  SDSF: 'Sharing decode WebSocket failed to connect after 10 attempts',
  SESF: 'Initialization of sharing encode WASM failed',
  SEHF: 'Sharing encode WebSocket failed to connect after 10 attempts',
  ADHF: 'Initialization of audio decode WASM failed',
  ADSF: 'Audio decode WebSocket failed to connect after 10 attempts',
  AEHF: 'Initialization of audio encode WASM failed',
  AESF: 'Audio encode WebSocket failed to connect after 10 attempts',
  VDHF: 'Initialization of video decode WASM failed',
  VDSF: 'Video decode WebSocket failed to connect after 10 attempts',
  VEHF: 'Initialization of video encode WASM failed',
  VESF: 'Video encode WebSocket failed to connect after 10 attempts',
  INITVDCERR: 'An error occurred when initializing video WebRTC DataChannel',
  INITADCERR: 'An error occurred when initializing audio WebRTC DataChannel',
};

var Zoom_Monitor = (function () {
  function Monitor() {
    this.base_time = null;
    this.monitor = '';
    this.last_get_monitor_time = 0;
    this.checkIsNecessaryLogMap = new Map();
    this.highfrequencyerror = new Map();
  }

  Monitor.prototype = {
    //adds abbr audio/decode/download/start
    //init
    init: function () {
      if (this.base_time) return;
      this.base_time = new Date().getTime();
      this.checkIsNecessaryLogMap = new Map();
      this.add_monitor('STARTMONITOR' + this.base_time);
    },
    // set_adi: function (re) {
    //     this.audio_decode.init = re;
    // },
    // set_adds: function (time) {
    //     this.audio_decode.download_s = time;
    // },
    // set_adde: function (time) {
    //     this.audio_decode.download_e = time;
    // },
    // set_adcs: function (time) {
    //     this.audio_decode.download_s = time;
    // },
    // set_adce: function (time) {
    //     this.audio_decode.download_e = time;
    // },
    // set_adse:function(time){
    //     this.audio_decode.socket_e = time;
    // },
    // set_aei: function (re) {
    //     this.audio_encode.init = re;
    // },
    // set_aeds: function (time) {
    //     this.audio_encode.download_s = time;
    // },
    // set_aede: function (time) {
    //     this.audio_encode.download_e = time;
    // },
    // set_aecs: function (time) {
    //     this.audio_encode.download_s = time;
    // },
    // set_aece: function (time) {
    //     this.audio_encode.download_e = time;
    // },
    // set_aese:function(time){
    //     this.audio_encode.socket_e = time;
    // },
    // set_vdi: function (re) {
    //     this.video_decode.init = re;
    // },
    // set_vdds: function (time) {
    //     this.video_decode.download_s = time;
    // },
    // set_vdde: function (time) {
    //     this.video_decode.download_e = time;
    // },
    // set_vdcs: function (time) {
    //     this.video_decode.download_s = time;
    // },
    // set_vdce: function (time) {
    //     this.video_decode.download_e = time;
    // },
    // set_vdse:function(time){
    //     this.video_decode.socket_e = time;
    // },
    // set_vei: function (re) {
    //     this.video_encode.init = re;
    // },
    // set_veds: function (time) {
    //     this.video_encode.download_s = time;
    // },
    // set_vede: function (time) {
    //     this.video_encode.download_e = time;
    // },
    // set_vecs: function (time) {
    //     this.video_encode.download_s = time;
    // },
    // set_vece: function (time) {
    //     this.video_encode.download_e = time;
    // },
    // set_vese:function(time){
    //     this.video_encode.socket_e = time;
    // },
    // set_sdi: function (re) {
    //     this.sharing_decode.init = re;
    // },
    // set_sdds: function (time) {
    //     this.sharing_decode.download_s = time;
    // },
    // set_sdde: function (time) {
    //     this.sharing_decode.download_e = time;
    // },
    // set_sdcs: function (time) {
    //     this.sharing_decode.download_s = time;
    // },
    // set_sdce: function (time) {
    //     this.sharing_decode.download_e = time;
    // },
    // set_sdse:function(time){
    //     this.sharing_decode.socket_e = time;
    // },
    // set_sei: function (re) {
    //     this.sharing_encode.init = re;
    // },
    // set_seds: function (time) {
    //     this.sharing_encode.download_s = time;
    // },
    // set_sede: function (time) {
    //     this.sharing_encode.download_e = time;
    // },
    // set_secs: function (time) {
    //     this.sharing_encode.download_s = time;
    // },
    // set_sece: function (time) {
    //     this.sharing_encode.download_e = time;
    // },
    // set_sese:function(time){
    //     this.sharing_encode.socket_e = time;
    // },
    add_monitor: function (log, additionalInformation) {
      if (!this.base_time) {
        this.init();
      }
      if (log) {
        try {
          if (errorLogDescriptions[log]) {
            let message = errorLogDescriptions[log];
            if (additionalInformation) {
              message = `${message}. Additional information: ${additionalInformation}`;
            }
            globalTracingLogger.error(message);
          }

          let send_instant = false;
          if (log[log.length - 1] == 'F' || log[log.length - 1] == 'f') {
            send_instant = true;
          }
          let delta_t = new Date().getTime() - this.base_time; //seconds
          if (this.monitor) {
            this.monitor = this.monitor + log + '(' + Math.ceil(delta_t) + ')';
          } else {
            this.monitor = log + '(' + Math.ceil(delta_t) + ')';
          }
          /** if size too large, server decode failed, rwg limit size to 2000 */
          if (this.monitor.length > 2000) {
            send_instant = true;
          }
          if (send_instant) {
            this.send_instant_monitor();
          }
        } catch (e) {
          globalTracingLogger.error(
            'Error when adding data to the monitor log',
            e
          );
        }
      }
    },
    get_monitor: function () {
      let zm = this.monitor;
      let now_t = new Date().getTime();
      if (
        zm == null ||
        !(zm.length > 80 || now_t - this.last_get_monitor_time > 3 * 60)
      ) {
        return '';
      }
      this.last_get_monitor_time = now_t;
      this.monitor = null;
      return 'WCL_M, ' + zm;
    },
    get_instant_monitor: function () {
      let zm = this.monitor;
      this.monitor = null;
      return 'WCL_M, ' + zm;
    },
    send_instant_monitor: function () {
      let zm = this.get_instant_monitor();
      if (zm) {
        jsMediaEngineVariables.sendMessageToRwg(jsEvent.MONITOR_LOG, {
          evt: 4167,
          seq: 1,
          body: {
            data: zm,
          },
        });
      }
    },
    add_monitor2(log) {
      if (!this.base_time) {
        this.init();
      }
      if (log) {
        let delta_t = new Date().getTime() - this.base_time;
        if (this.monitor) {
          this.monitor = this.monitor + log + '(' + delta_t + ')';
        } else {
          this.monitor = log + '(' + delta_t + ')';
        }
      }
    },
    add_monitor3(log) {
      if (!this.base_time) {
        this.init();
      }
      if (!log) {
        return;
      }
      if (!this.highfrequencyerror[log]) {
        this.highfrequencyerror[log] = 1;
      } else {
        this.highfrequencyerror[log] += 1;
      }

      const shouldReportHighFrequencyLog = isPowerOf2(
        this.highfrequencyerror[log]
      );

      if (shouldReportHighFrequencyLog) {
        this.add_monitor(log, `Occurred ${this.highfrequencyerror[log]} times`);
      }
    },
    /**
     * many exception logs are the same, do not send duplicated logs
     * checkIsNecessaryLogMap store N logs recently sent, and if new log is inside checkIsNecessaryLogMap, ignore the new log
     *
     * checkIsNecessaryLogMap store Max N logs, if storage is full, delete the first in logs ( first-in first-out )
     * @type logRawData {string}
     */
    checkIsNecessaryExceptionLogAndReturnRepeatTimes(logRawData) {
      let mapCacheMaxSize = 200; // The maximum number of logs stored in the map table
      let deletePiecesOnceTime = 20;
      let everyNTimesSendLog = 100; // N === value
      let isNecessary = true;
      let repeatNumber = 0;

      try {
        if (
          this.checkIsNecessaryLogMap.get(logRawData) &&
          this.checkIsNecessaryLogMap.get(logRawData) % everyNTimesSendLog !== 0
        ) {
          isNecessary = false;
        }

        repeatNumber = this.checkIsNecessaryLogMap.get(logRawData);

        return {
          isNecessary,
          repeatNumber: repeatNumber === undefined ? 0 : repeatNumber,
        };
      } catch (e) {
        return {
          isNecessary: true,
          repeatNumber: 0,
        };
      } finally {
        let oldCount = this.checkIsNecessaryLogMap.get(logRawData) || 0;
        this.checkIsNecessaryLogMap.set(logRawData, oldCount + 1);

        if (this.checkIsNecessaryLogMap.size > mapCacheMaxSize) {
          let deleteKeys = Array.from(this.checkIsNecessaryLogMap.keys()).slice(
            0,
            deletePiecesOnceTime
          );
          console.log('delete log cache keys', deleteKeys);
          deleteKeys.forEach((k) => this.checkIsNecessaryLogMap.delete(k));
        }
      }
    },
  };

  return new Monitor();
})();

export default Zoom_Monitor;
